import React, { useEffect, useState } from "react";
import styles from '../styles/SearchPage.module.css';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, FormCheck, Modal, ProgressBar } from "react-bootstrap";
import client from "../api/client";
import { FaRegEnvelope } from "react-icons/fa";
import Header from "../components/Header";
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineCloseCircle } from "react-icons/ai";
import about from '../images/Rectangle 37.png';
import Footer from "../components/Footer";
import Lawyer1 from '../assets/vector-lawyer.png';
import Lawfirm from '../assets/vector-logo.svg';
import { BsArrowRight, BsPatchCheck } from 'react-icons/bs';


const SearchPage = () => {

    document.title = "Search Page - Legal Fuse";

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [searchData, setSearchData] = useState();
    const [searchResultLawyers, setSearchResultLawyers] = useState();
    const [searchResultLawFirms, setSearchResultLawFirms] = useState();
    const [showSendMessage, setShowSendMessage] = useState(false);
    const [showSendMessage1, setShowSendMessage1] = useState(false);

    const [viewMore, setViewMore] = useState("null");
    const [viewMore1, setViewMore1] = useState("null");

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const [showTextarea, setShowTextarea] = useState(true)

    const [loading, setLoading] = useState(false);

    const [sendMessageLawyer, setSendMessageLawyer] = useState();
    const [sendMessageLawFirm, setSendMessageLawFirm] = useState();

    const [searchPreloader, setSearchPreloader] = useState(false);

    const type = searchParams.get("type");
    const AreaOfPractice = searchParams.get("areaOfPractice");
    const location = searchParams.get("location");
    const url = window.location.href;

    useEffect(() => {
        const getSearchResult = async () => {
            setLoading(true);
            const input = { "type": type === undefined ? "" : type, "AreaOfPractice": AreaOfPractice === undefined ? "" : AreaOfPractice, "location": location === undefined ? "" : location };
            if (type === "Lawyer") {
                const res = await client.post('/general/filter', input);
                if (res.data.statusCode === 200) {
                    setSearchResultLawyers(res?.data);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }
            if (type === "Law Firm") {
                const res = await client.post('/general/filter', input);
                if (res.data.statusCode === 200) {
                    setSearchResultLawFirms(res?.data);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }
            if (type === null) {
                setLoading(false);
            }

        }

        getSearchResult();
    }, []);

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(false);
        navigate(`./?type=${searchData.type === undefined ? "" : searchData.type}&areaOfPractice=${searchData.AreaOfPractice === undefined ? "" : searchData.AreaOfPractice}&location=${searchData.location === undefined ? "" : searchData.location}`);
        window.location.reload(false);
    };


    const handleMessageLawFirm = async (e) => {
        e.preventDefault();
        const input = {
            "type": "Law Firm",
            "email": viewMore1.emailAddress,
            "message": sendMessageLawFirm.message,
            "senderName": sendMessageLawFirm.senderName,
            "senderEmail": sendMessageLawFirm.senderEmail,
            "senderPhoneNumber": sendMessageLawFirm.senderPhoneNumber
        };
        const res = await client.post('/general/sendmessage', input);
        if (res.data.statusCode === 200) {
            alert(<span>Your message has been sent.</span>);
        }
        else {
            alert(<span>{res.data.message}</span>)
        }
    }

    const handleMessageLawyer = async (e) => {
        e.preventDefault();
        const input = {
            "type": "Lawyer",
            "email": viewMore.email,
            "message": sendMessageLawyer.message,
            "senderName": sendMessageLawyer.senderName,
            "senderEmail": sendMessageLawyer.senderEmail,
            "senderPhoneNumber": sendMessageLawyer.senderPhoneNumber
        };
        const res = await client.post('/general/sendmessage', input);
        if (res.data.statusCode === 200) {
            alert(<span>Your message has been sent.</span>);
        }
        else {
            alert(<span>{res.data.message}</span>)
        }
    }


    return (
        <section>
            {loading === true && <Modal centered size="sm" show={true}> <Modal.Body className="m-0"> <ProgressBar animated now={100} /> </Modal.Body></Modal>}
            <Header />
            <div className={styles.searchBg}>
                <div className="container">
                    <h2>Search for Lawyers and Law Firms</h2>
                </div>
            </div>
            <div id="search" className={styles.search}>
                <div className="container p-5">
                    <span className={styles.findLawyers}>Find a Lawyer</span> &nbsp;&nbsp;
                    <div className={styles.line} />

                    <Form onSubmit={handleSearch} className="row mt-3">
                        <div className="col-md-3">
                            <Form.Select required onChange={(e) => setSearchData({ ...searchData, type: e.target.value })} className={styles.searchInput}>
                                <option value="">Select type</option>
                                <option value="Lawyer">Lawyer</option>
                                <option value="Law Firm">Law Firm</option>
                            </Form.Select>
                        </div>
                        <div className="col-md-3    ">
                            <Form.Select required onChange={(e) => setSearchData({ ...searchData, AreaOfPractice: e.target.value })} className={styles.searchInput}>
                                <option>Select area of practice</option>
                                <option value="Corporate Commercial">Corporate Commercial</option>
                                <option value="Arbitration">Arbitration</option>
                                <option value="Dispute">Dispute</option>
                                <option value="Bankruptcy">Bankruptcy</option>
                                <option value="Business Law">Business Law</option>
                                <option value="Car Accident">Car Accident</option>
                                <option value="Criminal Defense">Criminal Defense</option>
                                <option value="Employment">Employment</option>
                                <option value="Estate Planning">Estate Planning</option>
                                <option value="Family Law">Family Law</option>
                                <option value="Government">Government</option>
                                <option value="Health Care & Social">Health Care & Social</option>
                                <option value="Immigration">Immigration</option>
                                <option value="Industry">Industry</option>
                                <option value="Intellectual Property">Intellectual Property</option>
                                <option value="International Law">International Law</option>
                                <option value="Litigation">Litigation</option>
                                <option value="Medical Malpractice">Medical Malpractice</option>
                                <option value="Personal Injury">Personal Injury</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Tax">Tax</option>
                            </Form.Select>
                        </div>
                        <div className="col-md-3">
                            <Form.Select required onChange={(e) => setSearchData({ ...searchData, location: e.target.value })} className={styles.searchInput}>
                                <option>Select location</option>
                                <option value="Abia">Abia</option>
                                <option value="Adamawa">Adamawa</option>
                                <option value="Akwa Ibom">Akwa Ibom</option>
                                <option value="Anambra">Anambra</option>
                                <option value="Bauchi">Bauchi</option>
                                <option value="Bayelsa">Bayelsa</option>
                                <option value="Benue">Benue</option>
                                <option value="Borno">Borno</option>
                                <option value="Cross River">Cross River</option>
                                <option value="Delta">Delta</option>
                                <option value="Ebonyi">Ebonyi</option>
                                <option value="Edo">Edo</option>
                                <option value="Ekiti">Ekiti</option>
                                <option value="Enugu">Enugu</option>
                                <option value="FCT">Federal Capital Territory</option>
                                <option value="Gombe">Gombe</option>
                                <option value="Imo">Imo</option>
                                <option value="Jigawa">Jigawa</option>
                                <option value="Kaduna">Kaduna</option>
                                <option value="Kano">Kano</option>
                                <option value="Katsina">Katsina</option>
                                <option value="Kebbi">Kebbi</option>
                                <option value="Kogi">Kogi</option>
                                <option value="Kwara">Kwara</option>
                                <option value="Lagos">Lagos</option>
                                <option value="Nasarawa">Nasarawa</option>
                                <option value="Niger">Niger</option>
                                <option value="Ogun">Ogun</option>
                                <option value="Ondo">Ondo</option>
                                <option value="Osun">Osun</option>
                                <option value="Oyo">Oyo</option>
                                <option value="Plateau">Plateau</option>
                                <option value="Rivers">Rivers</option>
                                <option value="Sokoto">Sokoto</option>
                                <option value="Taraba">Taraba</option>
                                <option value="Yobe">Yobe</option>
                                <option value="Zamfara">Zamfara</option>
                            </Form.Select>
                        </div>
                        <div className="col-md-2">
                            <button type="submit" className={styles.searchButton}> SEARCH</button>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="container pt-5 pb-5">
                <div className="row mt-3 mb-5">
                    <div className="col-md-3">
                        <h3>Search Result</h3>
                    </div>
                    <div className="col-md-9 m-0 p-0">
                        <hr style={{ height: "2px", color: "#000" }} />
                    </div>
                </div>
                {loading === true && <div><Spinner variant="primary" animation="grow" style={{ width: "100px", height: "100px" }} /></div>}
                {type === "Lawyer" && <> <div className="mt-3 pb-5 mb-5"><h3>Lawyers</h3></div>
                    {/* <div className="row mt-5 mb-5 me-auto justify-content-start">
                        {searchResultLawyers?.data?.length === 0 ? "No lawyer available" : searchResultLawyers?.slice(0, 4).filter(lawyers => lawyers.isVerify === true).map((list, key) => <div key={key} className="col-md-3 text-center boxCard m-auto">
                            <img src={list.ImgLocation ? `/legal-tech-uploads/${list.ImgLocation}` : Lawyer1} width="100%" />
                            <div className="overlay-image"></div>
                            <div className="inside-text-button ps-4 text-start text-white">
                                <span className="text-wrap"><b>{list?.firstName} {list?.lastName}</b></span><br /><span style={{fontSize: "14px"}}>{list?.AreaOfPractice}</span>
                            </div>
                            <button className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                        </div>)}
                    </div> */}
                    <div className="row mt-5 pt-5 mb-5" style={{ display: "flex", flexWrap: "wrap" }}>
                        {searchResultLawyers?.data?.length === 0 ? <h5>No result found for lawyer based on your search input</h5> : searchResultLawyers?.data?.filter(searchResultLawyers => searchResultLawyers.isVerify === true).map((list, key) =>
                            <div key={key} className="col-md-3 text-center boxCard m-auto">
                                <img src={list.ImgLocation ? `/legal-tech-uploads/${list.ImgLocation}` : Lawyer1} width="100%" />
                                <div className="overlay-image"></div>
                                <div className="inside-text-button ps-4 text-start text-white">
                                    <span className="text-wrap"><b>{list?.firstName} {list?.lastName}</b></span><br /><span style={{ fontSize: "14px" }}>{list?.AreaOfPractice}</span>
                                </div>
                                <button onClick={() => { setShow(true); setViewMore(list) }} className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                            </div>)}
                    </div>
                </>}
                {type === "Law Firm" && <><div className="mt-5 mb-3"><h3>Law Firms</h3></div>
                    <div className="row mt-5 mb-5" style={{ display: "flex", flexWrap: "wrap" }}>
                        {searchResultLawFirms?.data.length === 0 ? <h5>No result found for law firm based on your search input</h5> : searchResultLawFirms?.data?.filter(searchResultLawFirms => searchResultLawFirms.isVerify === true).map((list, key) =>

                            <div key={key} className="col-md-3 text-center boxCard m-auto">
                                <div className="bg-light faded-overlay rounded p-5">
                                    <img src={list.ImgLocation ? `/legal-tech-uploads/${list.ImgLocation}` : Lawfirm} width="50%" className="mb-5" />
                                </div>
                                <div className="inside-text-button text-white ps-4 text-start">
                                    <span>{list?.companyName}</span><br /><span style={{ fontSize: "14px" }}>{list?.areaOfPractice}</span>
                                </div>
                                <button onClick={() => { setShow1(true); setViewMore1(list) }} className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                            </div>
                            // <div className="col-md-3 col-sm-6 col-xs-12">
                            //     <div key={key} className="card m-2 p-4 justify-item-center" style={{height: "300px"}}>
                            //         <img src={`/legal-tech-uploads/${list.ImgLocation}`} onClick={() => {setViewMore1(list); setShow1(true)}} height="150px" width="100%" style={{cursor: "pointer", margin: "auto"}} alt="" />
                            //         <h5 className={styles.firmName}>{list.companyName}</h5>
                            //         {/* <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis retium feugiat scelerisque turpis facilisis amet. </p> */}
                            //         <button onClick={() => {setViewMore1(list); setShow1(true)}} style={{cursor: "pointer"}} className={styles.getLawyer}>View Profile</button>
                            //     </div>
                            // </div>
                        )}
                    </div>
                </>}
            </div>
            <Footer />

            <Modal size="xl" centered show={show} onHide={() => setShow(false)}>
                <Modal.Body className="text-left text-white" style={{ backgroundColor: "#021228", padding: "120px" }}>
                    <div className="text-end"><AiOutlineCloseCircle onClick={() => setShow(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    <div className="row mt-0 mb-3">
                        <div className="col-md-4">
                            <img src={`/legal-tech-uploads/${viewMore?.ImgLocation}`} height="100%" width="100%" alt="" />
                            {viewMore?.isVerify && <BsPatchCheck size={50} className="text-primary" style={{ position: "absolute", float: "right", margin: "250px 0px 0px -80px" }} />}
                        </div>
                        <div className="col-md-8 px-5">
                            <table width="100%">
                                <tr>
                                    <td><span><b>{viewMore?.firstName} {viewMore?.lastName}</b></span> <br /><span> Dispute Lawyer</span></td>
                                    <td><span><b>Phone Number</b> </span> <br /><span> {viewMore.phoneNumber}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td><span><b>Email Address</b> </span> <br /><span>{viewMore?.email}</span></td>
                                    <td><span><b>Location</b> </span> <br /><span> {viewMore?.Location}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td colSpan={2}><span><b>About {viewMore?.firstName} {viewMore?.lastName}</b> </span> <br /><span>{viewMore.BriefBio}</span></td>
                                </tr>
                                <tr><br /></tr>
                                <tr><td colSpan={2}><button onClick={() => setShowSendMessage(!showSendMessage)} className="btn text-white"> <FaRegEnvelope /> &nbsp; Send a message</button></td></tr>
                            </table>
                            <Form action="" onSubmit={handleMessageLawyer} hidden={showTextarea}>
                                <Form.Control type="text" placeholder="Name" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, senderName: e.target.value })}></Form.Control>
                                <Form.Control type="text" placeholder="Email" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, senderEmail: e.target.value })}></Form.Control>
                                <Form.Control type="text" placeholder="Phone Number" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, senderPhoneNumber: e.target.value })}></Form.Control>
                                <textarea onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, message: e.target.value })} className="w-100" placeholder="Your Message" rows={5}></textarea>
                                <button type="submit" className="getLawyer"> Send &nbsp; <Spinner hidden={loading} animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} /></button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="md" centered show={showSendMessage} onHide={() => setShowSendMessage(false)} style={{ backgroundColor: "#0A3B81F7" }}>
                <Modal.Body style={{ backgroundColor: "#0A3B81F7", border: "#FFFFFF solid 1px", borderRadius: "20px" }}>
                    <div className="text-end text-white"><AiOutlineCloseCircle onClick={() => setShowSendMessage(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    <Form action="" onSubmit={handleMessageLawyer} className="px-4 py-5 text-center text-white">
                        <h4>Send a <b className="fw-bold">message</b></h4>
                        <Form.Control type="text" className="mt-5 px-3 py-3" placeholder="Name" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, senderName: e.target.value })}></Form.Control>
                        <Form.Control type="text" className="mt-4 px-3 py-3" placeholder="Email" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, senderEmail: e.target.value })}></Form.Control>
                        <Form.Control as="textarea" className="mt-4 px-3 py-3" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, message: e.target.value })} placeholder="Your Message" rows={5} />
                        <button type="submit" className="primary-btn px-5 mt-5"> Send &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {loading ? <Spinner animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} /> : <BsArrowRight />}</button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal size="xl" centered show={show1} onHide={() => setShow1(false)}>
                <Modal.Body className="text-left text-white" style={{ backgroundColor: "#021228", padding: "120px" }}>
                    <div className="text-end"><AiOutlineCloseCircle onClick={() => setShow1(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    <div className="row mt-0 mb-3">
                        <div className="col-md-4">
                            <img src={`/legal-tech-uploads/${viewMore1?.ImgLocation}`} height="100%" width="100%" alt="" />
                            {viewMore?.isVerify && <BsPatchCheck size={50} className="text-primary" style={{ position: "absolute", float: "right", margin: "250px 0px 0px -80px" }} />}
                        </div>
                        <div className="col-md-8 px-5">

                            <table width="100%">
                                <tr>
                                    <td><span><b>{viewMore1?.companyName}</b></span> <br /><span> Dispute Law Firm</span></td>
                                    <td><span><b>Phone Number</b> </span> <br /><span> {viewMore1?.phoneNumber}</span></td>
                                    <td><span><b>Website</b> </span> <br /><span> {viewMore1?.website}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td><span><b>Email Address</b> </span> <br /><span>{viewMore1?.emailAddress}</span></td>
                                    <td><span><b>Location</b> </span> <br /><span> {viewMore1?.Location}</span></td>
                                    <td><span><b>Designation</b> </span> <br /><span> {viewMore1?.areaOfPractice}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td colSpan={2}><span><b>About {viewMore1?.firstName} {viewMore1?.lastName}</b> </span> <br /><span>{viewMore1?.BriefBio}</span></td>
                                </tr>
                                <tr><br /></tr>
                                <tr><td colSpan={2}><button onClick={() => setShowSendMessage1(!showSendMessage1)} className="btn text-white"> <FaRegEnvelope /> &nbsp; Send a message</button></td></tr>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default SearchPage;
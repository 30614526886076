import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Layout';
import Chat from '../../assets/chat.svg';
import ChatGreen from '../../assets/chat-green.svg';
import ChatWhite from '../../assets/chat-white.svg';
import client from '../../api/client';

const AdminDashboard = () => {

    const [lawyers, setLawyers] = useState([]);
    const [lawFirms, setLawFirms] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getlawyers = async () => {
            const res = await client.get(`/admin/authentication/getAllLawyer`);
            if (res?.data?.statusCode === 200) {
                setLawyers(res?.data?.data);
            }
            if (res.data.statusCode === 401) {
                window.location.reload();
            }
        }
        getlawyers();
    }, []);

    useEffect(() => {
        const getlawFirms = async () => {
            const res = await client.get(`/admin/authentication/getAllFirm`);
            if (res.data.statusCode === 200) {
                setLawFirms(res.data.data);
            }
            if (res.data.statusCode === 401) {
                window.location.reload();
            }
        }
        getlawFirms();
    }, []);

  return (
    <Sidebar pageTitle={"Dashboard"}>
        <div className='row'>
            <div className='col-md-4 mt-4'>
                <div className='bg-primary rounded p-4 d-flex justify-content-between'>
                    <div><font className='text-white' style={{fontSize: "12px"}}>TOTAL REQUESTS</font> <h5 className='text-white fw-bold py-3'>148</h5></div>
                    <img src={ChatWhite} width="80px" className="m-2 p-3 tertiary-bg rounded" />
                </div>
            </div>
            <div className='col-md-4 mt-4'>
            <div className='bg-white rounded p-4 d-flex justify-content-between'>
                    <div><font className='' style={{fontSize: "12px"}}>RATINGS</font> <h5 className='fw-bold py-3'>148</h5></div>
                    <img src={Chat} width="80px" className="m-2 p-3 tertiary-bg rounded" />
                </div>
            </div>
            <div className='col-md-4 mt-4'>
            <div className='bg-white rounded p-4 d-flex justify-content-between'>
                    <div><font className='' style={{fontSize: "12px"}}>TOTAL PROFILE CLICKS</font> <h5 className='fw-bold py-3'>148</h5></div>
                    <img src={ChatGreen} width="80px" className="m-2 p-3 tertiary-bg rounded" />
                </div>
            </div>
            <div className='col-md-4 mt-4'>
            <div className='bg-white rounded p-4 d-flex justify-content-between'>
                    <div><font className='' style={{fontSize: "12px"}}>TOTAL LAWYERS</font> <h5 className='fw-bold py-3'>{lawyers?.length}</h5></div>
                    <img src={Chat} width="80px" className="m-2 p-3 tertiary-bg rounded" />
                </div>
            </div>
            <div className='col-md-4 mt-4'>
            <div className='bg-white rounded p-4 d-flex justify-content-between'>
                    <div><font className='' style={{fontSize: "12px"}}>TOTAL LAW FIRMS</font> <h5 className='fw-bold py-3'>{lawFirms?.length}</h5></div>
                    <img src={ChatGreen} width="80px" className="m-2 p-3 tertiary-bg rounded" />
                </div>
            </div>
        </div>
    </Sidebar>
  );
}

export default AdminDashboard;
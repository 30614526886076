export default Object.freeze({
    LandingPage: "/",
    SignupLawyer: "/signup-lawyer",
    SignupLawFirm: "/signup-law-firm",
    ResetPasswordPage: "/password-reset",
    SearchPage: "/search-page",
    AboutPage: "/about-us",
    ContactPage: "/contact-us",
    DashboardPage: "/dashboard",
    ProfilePage: "/profile",
    SettingsPage: "/settings",
    AdminLogin: "/admin-login",
    AdminDashboard: "/admin/dashboard",
    LawFirmsPage: "/admin/lawfirms",
    LawyersPage: "/admin/lawyers",
    Blog1: "/legal-news/firm-deploys-technology-for-best-practices",
    Blog2: "/legal-news/law-and-fake-news-in-nigeria",
    Blog3: "/legal-news/robotic-lawyer-a-threat-to-conventional-practice"
});
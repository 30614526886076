import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import about from '../../images/Rectangle 37.png';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "../../styles/Blog.module.css";

const Blog1 = () => {

    document.title = "Contact Us - Legal Fuse";

    return (
        <section>
            <Header />
            <div className="container mt-5 mb-5 pb-5">
                <div className="row col-md-10 m-auto">
                    <h3 className={styles.title}>Firm deploys technology for best practices</h3>
                    <img src="https://guardian.ng/wp-content/uploads/2023/04/Gbolahan-Elias.jpg" className="mb-3 mt-3" />
                    <p><b>Appoints new executive management partners</b></p>
                    <p>G. Elias, a leading Nigerian business law firm, has reiterated its commitment to the use of technology for best practices in carrying out its activities. The firm made this known at an event, where it announced the appointment of executive partners to lead the management team and oversee its business.</p>
                    <p> According to the firm, effective April 1, 2023, Fidelis Adewole and Okechukwu Okoro became its Managing Partner and Deputy Managing Partner respectively. Each of them has been responsible for managing critical multinational and other clients and is recognised in international directories of lawyers.</p>
                    <p> Adewole has over 17 years experience in the areas of corporate law (including serial mergers), litigation and arbitration. Okoro has over 12 years of experience and his main practice areas are energy, disputes and finance including investments in renewables and billion-dollar forward sale financings for upstream oil players.</p>
                    <p> G. Elias has 60 lawyers serving Nigerian, Pan-African and international clients ranging from top global multinational enterprises to ambitious, home-grown fledglings, governments, and government-controlled enterprises.</p>
                    <p> Founding Partner, Prof. Elias (SAN), said: “The future of the firm could not be in better hands. I warmly seize this opportunity fully to re-embrace my true love, core practising law and working on specific clients' instructions.”</p>
                    <p> Former Managing Partner, Fred Onuobia (SAN), congratulated the new executives on their appointment and thanked them for accepting the mandate to lead the firm to a greater height. He also reaffirmed the firm's commitment to global standard law practice even with the new leadership.</p>
                    <p> “We are excited by the prospect that our new duo of exceptional millennial leaders will deliver record outperformance on every key metrics for years to come. We are committed to giving the best advice and consultation to our clients and key stakeholders in every sector we operate.</p>
                    <p> “We will continue to prioritise technical proficiency and creativity in developing practical solutions to client’s problems,” he said.
                    </p>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default Blog1;
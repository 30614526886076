import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutBG from '../assets/legal-expert.png';
import FadedLogo from '../assets/faded-logo.png';
import MissionBG from '../assets/still-life-with-scales-justice.png';
import Partern1 from '../assets/partern.svg';
import Lawyer1 from '../assets/vector-lawyer.png';
import Lawyer2 from '../assets/vector-layer01.png';
import Lawyer3 from '../assets/vectorlaw09.png';
import Lawyer4 from '../assets/vector-law123.png';

const AboutPage = () => {

    document.title = "About Us - Legal Fuse";

    const [searchData, setSeartchData] = useState();

    const [show, setShow] = useState(false);

    return (
        <main>
            <Header />
            <section>
                <div className="container mt-5">
                    <img src={AboutBG} width="100%" style={{ marginBottom: "-90px" }} />
                </div>
                <div className="container">
                    <div className="row mt-5 pb-5">
                        <div className="col-md-4 m-auto pt-5">
                            <img src={FadedLogo} width="100%" />
                        </div>
                        <div className="col-md-7 px-4">
                        <button className="secondary-btn fs-2 fw-bold py-4 px-5 mb-4">About Us</button>
                            <div className="lh-lg"><p>Legal Fuse is a fast growing online law information sites and has grown to be a top-most popular legal resource on the internet. It includes extensive directories of law firms and lawyers in Nigeria at the moment.</p><p> Legal Fuse was launched in 2022, and its purpose is to make information about lawyers, law firms, and the law easily and freely accessible to the legal profession, businesses, and consumers all over Nigeria. </p><p>Today, over 10,000 visitors each month turn to legalfuse.net when looking for lawyers and legal help. Offering highly engaged interaction with visitors, thousands and thousands of lawyers and law firms enjoy the benefits of being included in constectechnologies.com's directories and taking advantage of its other services, such as free leads, for focused marketing to their target audiences.</p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>

                    <div className="row mt-5 pt-5">
                        <div className="col-md-4 m-auto">
                            <img src={MissionBG} width="100%" />
                        </div>
                        <div className="col-md-6 m-auto px-4">
                        <div className="lh-lg pb-5">
                            <h4 className="pb-3">Our Mission</h4>
                            <p>Our mission is to make information about lawyers, law firms, and the law easily and freely accessible to the legal profession, businesses, and consumers all over Nigeria.</p>
                            </div>
                            <div className="lh-lg pb-5">
                            <h4 className="pb-3">Our Vision</h4>
                            <p>Our vision is to empowering Nigeria's Legal Ecosystem through Unrivaled Online Accessibility and Engagement</p>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container pt-5">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-4 ps-5 m-auto d-sm-none d-md-block">
                            <img src={Partern1} width="100%" />
                        </div>
                        <div className="col-md-5 pt-5 mt-5">
                            <div className="textInside">
                                <h4>Meet the team</h4>
                                <h5>Our team of professionals</h5>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </section>

            <section className="pb-5" style={{ marginTop: "-50px" }}>
                <div className="container pb-5">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-3 text-center mb-5 boxCard m-auto">
                            <img src={Lawyer1} width="100%" />
                            <div className="overlay-image"></div>
                            <div className="inside-text-button ps-5 text-start text-white">
                                <span>John Doe</span><br /><span>John Doe</span>
                            </div>
                            <button className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                        </div>
                        <div className="col-md-3 text-center mb-5 boxCard m-auto">
                            <img src={Lawyer2} width="100%" />
                            <div className="overlay-image"></div>
                            <div className="inside-text-button ps-5 text-start text-white">
                                <span>John Doe</span><br /><span>John Doe</span>
                            </div>
                            <button className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                        </div>
                        <div className="col-md-3 text-center mb-5 boxCard m-auto">
                            <img src={Lawyer3} width="100%" />
                            <div className="overlay-image"></div>
                            <div className="inside-text-button ps-5 text-start text-white">
                                <span>John Doe</span><br /><span>John Doe</span>
                            </div>
                            <button className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                        </div>
                        <div className="col-md-3 text-center mb-5 boxCard m-auto">
                            <img src={Lawyer4} width="100%" />
                            <div className="overlay-image"></div>
                            <div className="inside-text-button ps-5 text-start text-white">
                                <span>John Doe</span><br /><span>John Doe</span>
                            </div>
                            <button className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    )
}

export default AboutPage;
import { create } from "apisauce";

const jwtoken = JSON.parse(localStorage.getItem("user"));

// define the api

const client = create({
    baseURL: "https://www.legalfuse.net/api",
    headers: {
        Accept: 'application/json; charset=utf-8',
        "Authorization": `Bearer ${jwtoken?.token}`,
    },
});

export default client;

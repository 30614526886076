import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import client from "../api/client";
import styles from "../styles/SignupLawyer.module.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import FindLawyer from '../images/legal-fuse.png';
import Group7 from '../images/Group7.png';
import law from '../images/law.png';
import successIcon from '../images/successIcon.svg';
import {CgArrowLongLeft} from "react-icons/cg";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import { MdOutlineArrowRightAlt, MdClose } from "react-icons/md";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Spinner from 'react-bootstrap/Spinner';
import Alert from "react-bootstrap/Alert";

const SignupLawyer = () => {

    document.title = "Register as a Lawyer - Legal Fuse";

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('first');

    const [signUpLawyer, setSignUpLawyer] = useState();

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const [loading, setLoading] = useState(true);

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordInput1, setPasswordInput1] = useState("");
    const handlePasswordChange1 = (evnt) => {
        setPasswordInput1(evnt.target.value);
    }
    const togglePassword1 = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }

    const handleNext = async(e) => {
        e.preventDefault();
        const MAX_FILE_SIZE = 1024 // 1MB
        const uploadFileSize = signUpLawyer.file.size / 1024;
        if (uploadFileSize > MAX_FILE_SIZE) {
            
        }
        setActiveTab("second");
        console.log();
    }
    const handleNext1 = async(e) => {
        e.preventDefault();
        const AreaOfPractice = skillOptions1.map(list => (list.value));
        const AreaOfExpertise = skillOptions.map(list => (list.value));
        setSignUpLawyer({...signUpLawyer, AreaOfPractice: AreaOfPractice, AreaOfExpertise: AreaOfExpertise});
        setActiveTab("third");
        console.log(skillOptions, skillOptions1);
    }
    const handleNext2 = async(e) => {
        e.preventDefault();
        setLoading(false);
        const input = ({...signUpLawyer, password: passwordInput});
        console.log(input);
        const formData = new FormData();
        formData.append("email", signUpLawyer.email);
        formData.append("password", passwordInput);
        formData.append("firstName", signUpLawyer.firstName);
        formData.append("lastName", signUpLawyer.lastName);
        formData.append("addrress", signUpLawyer.addrress);
        formData.append("phoneNumber", signUpLawyer.phoneNumber);
        formData.append("nbaNumber", signUpLawyer.nbaNumber);
        formData.append("supremeCourtNumber", signUpLawyer.supremeCourtNumber);
        formData.append("AreaOfPractice", signUpLawyer.AreaOfPractice);
        formData.append("AreaOfExpertise", signUpLawyer.AreaOfExpertise);
        formData.append("BriefBio", signUpLawyer.BriefBio);
        formData.append("Location", signUpLawyer.Location);
        formData.append("file", signUpLawyer.file);
        
        const res = await client.post('/lawyer/authentication/registerUser', formData);
        if (res.data.statusCode === 200  ) {
            setLoading(true);
            setShow(true);
        }
        else {
            setLoading(true);
            alert(res.data.message);
        }
    }

    const [skillOptions, setSkillOptions] = useState(null);
    const [skillOptions1, setSkillOptions1] = useState(null);

    const options = [
        { value: 'Banking', label: 'Banking' },
        { value: 'Capital Markets (Debt)', label: 'Capital Markets (Debt)' },
        { value: 'Capital Markets (Equity)', label: 'Capital Markets (Equity)' },
        { value: 'Election Petition', label: 'Election Petition' },
        { value: 'M&A', label: 'M&A' },
        { value: 'Project Developmet (Energy)', label: 'Project Developmet (Energy)' },
        { value: 'Project Development (Infrastruture)', label: 'Project Development (Infrastruture)' },
        { value: 'Restructuring and Insolvency', label: 'Restructuring and Insolvency' }
      ];

      const options1 = [
        { value: 'Corporate Commercial', label: 'Corporate Commercial' },
        { value: 'Arbitration', label: 'Arbitration' },
        { value: 'Dispute', label: 'Dispute' },
        { value: 'Bankruptcy', label: 'Bankruptcy' },
        { value: 'Business Law', label: 'Business Law'},
        { value: 'Car Accident', label: 'Car Accident'},
        { value: 'Criminal Defense', label: 'Criminal Defense'},
        { value: 'Employment', label: 'Employment'},
        { value: 'Estate Planning', label: 'Estate Planning'},
        { value: 'Family Law', label: 'Family Law'},
        { value: 'Government', label: 'Government'},
        { value: 'Health Care & Social', label: 'Health Care & Social'},
        { value: 'Immigration', label: 'Immigration'},
        { value: 'Industry', label: 'Industry'},
        { value: 'Intellectual Property', label: 'Intellectual Property'},
        { value: 'International Law', label: 'International Law'},
        { value: 'Litigation', label: 'Litigation'},
        { value: 'Medical Malpractice', label: 'Medical Malpractice'},
        { value: 'Personal Injury', label: 'Personal Injury'},
        { value: 'Real Estate', label: 'Real Estate'},
        { value: 'Tax', label: 'Tax'}
      ];


    //////////////////////////////////////////////////////////////////////////

    const [signIn, setSignIn] = useState();
    const [errorLogin, setErrorLogin] = useState();
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(false);
        const res = await client.post('/lawyer/authentication/login', signIn);
        if (res.data.statusCode === 200) {
            window.localStorage.setItem("user", JSON.stringify(res.data));
            setLoading(true);
            navigate('/dashboard');
        }
        else {
            setErrorLogin(
                <Alert variant="danger">
                    {res.message}
                </Alert>
            );
        }
    };



    return (
        <div className="container">
            <Navbar collapseOnSelect expand="lg" variant="light" className="mt-3 mb-2">
                <Container>
                    <Navbar.Brand href="/"><img src={FindLawyer} width="50px" alt="" /> &nbsp;&nbsp;<span className={styles.brandName}>LEGAL </span> <span className={styles.brandName1}>FUSE</span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav>
                            <Nav.Link href="/" className={styles.backButton}>Back to Website <MdOutlineArrowRightAlt /></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="row mt-4">
                <div className="col-md-7">
                    <h2 className={styles.title}>Register a new account</h2>
                    <button onClick={() => navigate('/signup-lawyer')} className={styles.switchButtton}><img src={law} alt="" /><span className={styles.blockText}><label>Register as a </label><br/>Lawyer</span></button>
                    <button onClick={() => navigate('/signup-law-firm')} className={styles.switchButtton1Inactive}><img src={law} alt="" /><span className={styles.blockText}><label>Register as a </label><br/>Law Firm</span></button>

                    <Form action="" onSubmit={handleNext} encType="multipart/form-data">{activeTab === 'first' && <div>
                        <div className="row mt-5">
                            <div className="col-md-1"><span className={styles.tabCount}>1<label>/3</label></span> </div>
                            <div className="col-md-6"><span className={styles.formLabel}>Personal Details <label>Provide your contact details in the fields below</label></span></div>
                        </div>
                        <div className={styles.onboardingForm}>
                            <hr/>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;First Name *</Form.Label>
                                    <Form.Control type="text" required placeholder="Mark" onChange={(e)=> setSignUpLawyer({ ...signUpLawyer, firstName: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Last Name *</Form.Label>
                                    <Form.Control type="text" required placeholder="John" onChange={(e)=> setSignUpLawyer({...signUpLawyer, lastName: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Email Address *</Form.Label>
                                    <Form.Control type="email" required placeholder="mark.j@gmail.com" onChange={(e)=> setSignUpLawyer({...signUpLawyer, email: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Phone Number *</Form.Label>
                                    <Form.Control type="text" required placeholder="08976554321" onChange={(e)=> setSignUpLawyer({...signUpLawyer, phoneNumber: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Upload Passport Photo *</Form.Label>
                                    <Form.Control type="file" required accept="image/x-png,image/jpg,image/jpeg" onChange={(e)=> setSignUpLawyer({...signUpLawyer, file: e.target.files[0]})} className={styles.inputField}></Form.Control><label className={styles.formTag}>Maximum file size is 10mb</label>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label for="locations" className={styles.formLabel}>&nbsp;&nbsp;Location *</Form.Label>
                                    <Form.Select name="locations" id="locations" required className={styles.inputField} onChange={(e)=> setSignUpLawyer({...signUpLawyer, Location: e.target.value})}>
                                        <option value="">Select location</option>
                                        <option value="Abia">Abia</option>
                                        <option value="Adamawa">Adamawa</option>
                                        <option value="Akwa Ibom">Akwa Ibom</option>
                                        <option value="Anambra">Anambra</option>
                                        <option value="Bauchi">Bauchi</option>
                                        <option value="Bayelsa">Bayelsa</option>
                                        <option value="Benue">Benue</option>
                                        <option value="Borno">Borno</option>
                                        <option value="Cross River">Cross River</option>
                                        <option value="Delta">Delta</option>
                                        <option value="Ebonyi">Ebonyi</option>
                                        <option value="Edo">Edo</option>
                                        <option value="Ekiti">Ekiti</option>
                                        <option value="Enugu">Enugu</option>
                                        <option value="FCT">Federal Capital Territory</option>
                                        <option value="Gombe">Gombe</option>
                                        <option value="Imo">Imo</option>
                                        <option value="Jigawa">Jigawa</option>
                                        <option value="Kaduna">Kaduna</option>
                                        <option value="Kano">Kano</option>
                                        <option value="Katsina">Katsina</option>
                                        <option value="Kebbi">Kebbi</option>
                                        <option value="Kogi">Kogi</option>
                                        <option value="Kwara">Kwara</option>
                                        <option value="Lagos">Lagos</option>
                                        <option value="Nasarawa">Nasarawa</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Ogun">Ogun</option>
                                        <option value="Ondo">Ondo</option>
                                        <option value="Osun">Osun</option>
                                        <option value="Oyo">Oyo</option>
                                        <option value="Plateau">Plateau</option>
                                        <option value="Rivers">Rivers</option>
                                        <option value="Sokoto">Sokoto</option>
                                        <option value="Taraba">Taraba</option>
                                        <option value="Yobe">Yobe</option>
                                        <option value="Zamfara">Zamfara</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="row col-md-4 m-auto mt-5 mb-5"><button type="submit" className={styles.backButton}>Next <MdOutlineArrowRightAlt /></button></div>
                        </div>
                    </div>}</Form>

                    <Form action="" onSubmit={handleNext1}>{activeTab === 'second' && <div>
                        <div className="row mt-5">
                            <div className="col-md-2" style={{marginRight: "-30px"}}><span className={styles.tabCount}>2<label>/3</label></span> </div>
                            <div className="col-md-6"><span className={styles.formLabel}>Professional Details <label>Provide your license details as a Lawyer in the fields below</label></span></div>
                        </div>
                        <div className={styles.onboardingForm}>
                            <hr/>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;NBA Number *</Form.Label>
                                    <Form.Control type="text" required placeholder="089735487FGJK" onChange={(e)=> setSignUpLawyer({...signUpLawyer, nbaNumber: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Supreme Court Number *</Form.Label>
                                    <Form.Control type="text" required placeholder="234YHGJI" onChange={(e)=> setSignUpLawyer({...signUpLawyer, supremeCourtNumber: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Area of Practice *</Form.Label>
                                    <Select required
                                        isMulti
                                        name="skills"
                                        options={options1}
                                        onChange={setSkillOptions1}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Area of Expertise</Form.Label>
                                    <Select required
                                        isMulti
                                        name="skills"
                                        options={options}
                                        onChange={setSkillOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Place of Work *</Form.Label>
                                    <Form.Control type="text" required placeholder="Ikeja, Lagos" onChange={(e)=> setSignUpLawyer({...signUpLawyer, addrress: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4 p-0">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;A bit about yourself *</Form.Label>
                                    <Form.Text as="textarea" maxlength="500" required placeholder="Tell us about yourself" rows={3} onChange={(e)=> setSignUpLawyer({...signUpLawyer, BriefBio: e.target.value})} className={styles.textareaField}></Form.Text>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5">
                                <div className="col-md-6">
                                    <button type="button" onClick={() => setActiveTab('first')} className={styles.backButton}> <CgArrowLongLeft /> Previous</button>
                                </div>
                                <div className="col-md-6 text-end">
                                    <button type="submit" className={styles.backButton}>Next <MdOutlineArrowRightAlt /></button>
                                </div>
                            </div>
                        </div>
                    </div>}</Form>

                    <Form action="" onSubmit={handleNext2}>{activeTab === 'third' && <div>
                        <div className="row mt-5">
                            <div className="col-md-2" style={{marginRight: "-30px"}}><span className={styles.tabCount}>3<label>/3</label></span> </div>
                            <div className="col-md-6"><span className={styles.formLabel}>Create Password <label>Last step is to create a password for your account</label></span></div>
                        </div>
                        <div className={styles.onboardingForm}>
                            <hr/>
                            {/* <Form.Control type={passwordType} required onChange={handlePasswordChange} value={passwordInput} className={styles.input} placeholder="***************"></Form.Control><div className={styles.pass} >{passwordType === "password" ? <FaEyeSlash onClick={() => togglePassword()} /> : <FaEye onClick={() => togglePassword()} />}</div>
                            <Form.Label className="mt-2">Confirm Password &nbsp; &nbsp; &nbsp; &nbsp; {passwordInput !== passwordInput1 ? <span className="text-danger">Password does not match.</span> : passwordInput.length > 0 && passwordInput1.length > 0 && passwordInput === passwordInput1 ? <span className="text-success">Password match.</span> : <></> }</Form.Label>
                            <Form.Control type={passwordType1} required onChange={handlePasswordChange1} value={passwordInput1} className={styles.inputField} placeholder="***************"></Form.Control><div className={styles.pass} >{passwordType1 === "password" ? <FaEyeSlash onClick={() => togglePassword1()} /> : <FaEye onClick={() => togglePassword1()} />}</div> */}
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Create Password *</Form.Label>
                                    <Form.Control type={passwordType} required onChange={handlePasswordChange} value={passwordInput} className={styles.inputField} placeholder="***************"></Form.Control><div className={styles.pass} >{passwordType === "password" ? <FaEyeSlash onClick={() => togglePassword()} /> : <FaEye onClick={() => togglePassword()} />}</div>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Conf<b>i</b>rm Password * &nbsp; {passwordInput !== passwordInput1 ? <span className={styles.passValid} style={{fontSize: "11px"}}>Password does not match.</span> : passwordInput.length > 0 && passwordInput1.length > 0 && passwordInput === passwordInput1 ? <span className="text-success" style={{fontSize: "11px"}}>Password match.</span> : <></> }</Form.Label>
                                    <Form.Control type={passwordType1} required onChange={handlePasswordChange1} value={passwordInput1} className={styles.inputField} placeholder="***************"></Form.Control><div className={styles.pass} >{passwordType1 === "password" ? <FaEyeSlash onClick={() => togglePassword1()} /> : <FaEye onClick={() => togglePassword1()} />}</div>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5">
                                <div className="col-md-6">
                                    <button type="button" onClick={() => setActiveTab('second')} className={styles.backButton}> <CgArrowLongLeft /> Previous</button>
                                </div>
                                <div className="col-md-6 text-end">
                                    <button type="submit" disabled={passwordInput !== passwordInput1} className={styles.backButton}>Submit &nbsp; <Spinner hidden={loading} animation="border" variant="light" style={{fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle"}} /></button>
                                </div>
                            </div>
                            {/* <div className="row col-md-4 m-auto mt-5 mb-5"><button type="button" onClick={() => setShow(true)} className={styles.backButton}>Submit</button></div> */}
                        </div>
                    </div>}</Form>
                </div>
                <div className="col-md-5"><img src={Group7} className={styles.onboardingBg} alt="" /></div>
            </div>
            <Modal size="md" centered show={show} onHide={()=> setShow(false)}>
                <Modal.Body className="text-center">
                    <div style={{display: 'block', textAlign: 'right', marginRight: '10px'}}><MdClose onClick={()=> setShow(false)} /></div>
                    <div className={styles.modalSuccess}>
                        <img src={successIcon} alt="" />
                        <h4 className={styles.successBig}>Registration Completed</h4>
                        <p className={styles.successSmall}>You have successfully registered on Legal Fuse. </p>
                        <button onClick={()=> {setShow(false); setShow1(true)}} className={styles.backButton}>Login Here</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="md" centered show={show1} onHide={()=> setShow1(false)}>
                <Modal.Body>
                    <div style={{display: 'block', textAlign: 'right', marginRight: '10px'}}><MdClose onClick={()=> setShow(false)} /></div>
                    <div className="pb-2">
                        <Form action="" onSubmit={handleLogin} className={styles.onboardingForm}>
                        <h3 align="center">Welcome back</h3>
                        <h5 align="center">Login here</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Email Address:</Form.Label>
                                    <Form.Control type="email" required onChange={(e)=> setSignIn({...signIn, email: e.target.value})} placeholder="Mark" className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Password</Form.Label>
                                    <Form.Control type="password" required onChange={(e)=> setSignIn({...signIn, password: e.target.value})} placeholder="John" className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Account Type (Lawyer OR Law Firm)</Form.Label>
                                    <Form.Select required onChange={(e)=> setSignIn({...signIn, accountType: e.target.value})} className={styles.inputField}>
                                        <option>Select your option</option>
                                        <option value="Lawyer">Lawyer</option>
                                        <option value="Law Firm">Law Firm</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="text-center mt-3"><button type="submit"  className={styles.backButton}>Login <Spinner hidden={true} animation="border" variant="light" /></button></div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SignupLawyer;
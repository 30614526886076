import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";
import LandingPage from "./pages/LandingPage";
import SignupLawyer from "./pages/SignupLawyer";
import SignupLawFirm from "./pages/SignupLawFirm";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import SearchPage from "./pages/SearchPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import ProfilePage from "./pages/dashboard/ProfilePage";
import SettingsPage from "./pages/dashboard/SettingsPage";
import AdminLogin from "./pages/admin/AdminLogin";
import AdminDashboard from "./pages/admin/AdminDashboard";
import LawFirmsPage from "./pages/admin/LawFirms";
import LawyersPage from "./pages/admin/Lawyers";
import Blog1 from "./pages/legal-news/firm-deploys-technology-for-best-practices";
import Blog2 from "./pages/legal-news/law-and-fake-news-in-nigeria";
import Blog3 from "./pages/legal-news/robotic-lawyer-a-threat-to-conventional-practice";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact={true} path={routes.LandingPage} element={<LandingPage />} />
        <Route exact={true} path={routes.SignupLawyer} element={<SignupLawyer />} />
        <Route exact={true} path={routes.SignupLawFirm} element={<SignupLawFirm />} />
        <Route exact={true} path={routes.ResetPasswordPage} element={<ResetPasswordPage />} />
        <Route exact={true} path={routes.AboutPage} element={<AboutPage />} />
        <Route exact={true} path={routes.ContactPage} element={<ContactPage />} />
        <Route exact={true} path={routes.SearchPage} element={<SearchPage />} />
        <Route exact={true} path={routes.DashboardPage} element={<DashboardPage />} />
        <Route exact={true} path={routes.ProfilePage} element={<ProfilePage />} />
        <Route exact={true} path={routes.SettingsPage} element={<SettingsPage />} />
        <Route exact={true} path={routes.AdminLogin} element={<AdminLogin />} />
        <Route exact={true} path={routes.AdminDashboard} element={<AdminDashboard />} />
        <Route exact={true} path={routes.LawFirmsPage} element={<LawFirmsPage />} />
        <Route exact={true} path={routes.LawyersPage} element={<LawyersPage />} />
        <Route exact={true} path={routes.Blog1} element={<Blog1 />} />
        <Route exact={true} path={routes.Blog2} element={<Blog2 />} />
        <Route exact={true} path={routes.Blog3} element={<Blog3 />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/DashboardPage.module.css";
import { MdClose, MdStar } from "react-icons/md";
import { Form, Modal, Spinner, Table } from "react-bootstrap";
import client from "../../api/client";
import Sidebar from "../../components/Layout";
import Chat from '../../assets/chat.svg';
import ChatGreen from '../../assets/chat-green.svg';
import ChatWhite from '../../assets/chat-white.svg';
import { BsChevronRight, BsPatchCheck } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";


const DashboardPage = () => {

    document.title = "Account Dashboard - Legal Fuse";

    const navigate = useNavigate();

    const location = useLocation();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const [invite, setInvite] = useState();

    const getUser = JSON.parse(localStorage.getItem("user"));

    const authenticate = () => {
        if (localStorage.getItem("user") === null || localStorage.getItem("user") === undefined) {
            navigate('/');
        }
    }
    useEffect(() => {
        authenticate();
    }, [getUser]);

    const handleLogout = () => {
        if (localStorage.getItem("user") !== null && localStorage.getItem("user") !== undefined) {
            window.localStorage.removeItem("user");
        }
    }

    const handleInvite = async (e) => {
        e.preventDefault();
        setLoading(true);
        const type = getUser?.data?.companyName === null ? "Law Firm" : "Lawyer";
        const input = {
            "type": type,
            "senderEmail": type === "Law Firm" ? getUser?.data?.emailAddress : getUser?.data?.email,
            "receiverName": invite.email,
            "receiverEmail": invite.email
        };
        const res = await client.post('/general/invite', input);
        if (res.data.statusCode === 200) {
            alert(<p>{res.data.message[0]}</p>);
        }
        else {
            alert(<p>{res.data.message[0]}</p>);
        }
        setLoading(false);
        setShow(false);

    }


    const [updateLogo, setUpdateLogo] = useState();

    const handleUpdateLogo = async (e) => {
        const formData = new FormData();
        formData.append("type", "Law Firm");
        formData.append("file", updateLogo);
        const res = client.post(`/firm/authentication/upload`, formData);
        if (res.data.statusCode === 200) {
            const updatedData = {
                "token": getUser?.token,
                "data": res.data.data
            }
            window.localStorage.setItem("user", JSON.stringify(updatedData));
            alert("Profile Logo Updated Successfully");
        }
        else {
            alert("Profile Logo Updated Failed");
        }
    }

    const [updatePhoto, setUpdatePhoto] = useState();

    const handleUpdatePhoto = async (e) => {
        const formData = new FormData();
        formData.append("type", "Lawyer");
        formData.append("file", updatePhoto);
        const res = client.post(`/lawyer/authentication/upload`, formData);
        if (res.data.statusCode === 200) {
            const updatedData = {
                "token": getUser?.token,
                "data": res.data.data
            }
            window.localStorage.setItem("user", JSON.stringify(updatedData));
            alert("Profile Logo Updated Successfully");
        }
        else {
            alert("Profile Logo Updated Failed");
        }
    }


    return (
        <Sidebar pageTitle={"Dashboard"}>
            <div className='row'>
                <div className='col-md-4 mt-4'>
                    <div className='bg-primary rounded p-4 d-flex justify-content-between'>
                        <div><font className='text-white' style={{ fontSize: "12px" }}>TOTAL REQUESTS</font> <h5 className='text-white fw-bold py-3'>148</h5></div>
                        <img src={ChatWhite} width="80px" className="m-2 p-3 tertiary-bg rounded" />
                    </div>
                </div>
                <div className='col-md-4 mt-4'>
                    <div className='bg-white rounded p-4 d-flex justify-content-between'>
                        <div><font className='' style={{ fontSize: "12px" }}>RATINGS</font> <h5 className='fw-bold pt-3 pb-0 mb-0'>148</h5><span className="text-warning"><MdStar /><MdStar /><MdStar /><MdStar /><MdStar /></span></div>
                        <img src={Chat} width="80px" className="m-2 p-3 tertiary-bg rounded" />
                    </div>
                </div>
                <div className='col-md-4 mt-4'>
                    <div className='bg-white rounded p-4 d-flex justify-content-between'>
                        <div><font className='' style={{ fontSize: "12px" }}>TOTAL PROFILE CLICKS</font> <h5 className='fw-bold py-3'>148</h5></div>
                        <img src={ChatGreen} width="80px" className="m-2 p-3 tertiary-bg rounded" />
                    </div>
                </div>
            </div>

            {getUser?.data?.nbaNumber ? <>
                <div className="card px-4 mt-5">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-4">
                            <div className="text-center border rounded">
                                {getUser?.data?.ImgLocation === null || getUser?.data?.ImgLocation === undefined ? <FaUserCircle style={{ fontSize: "200px" }} /> : <img src={`/legal-tech-uploads/${getUser?.data?.ImgLocation}`} width="100%" alt="" />}
                                <Form.Control type="file" onChange={(e) => setUpdatePhoto(e.target.files[0])} className="mb-2" style={{ width: "200px", margin: "auto" }}></Form.Control>
                                <button type="submit" className="primary-btn" onClick={() => handleUpdatePhoto()}><AiOutlineEdit /> Update Photo</button></div>


                            <img src={`/legal-tech-uploads/${getUser?.data?.ImgLocation}`} height="100%" width="100%" alt="" />
                            {getUser?.data?.isVerify == true && <BsPatchCheck size={50} className="text-primary" style={{ float: "right", margin: "-80px 30px 0px 0px" }} />}
                        </div>
                        <div className="col-md-8 px-5">
                            <Table responsive className="table" width="100%">
                                <tr>
                                    <td><span><b>{getUser?.data?.firstName} {getUser?.data?.lastName}</b></span> <br /><span> Dispute Lawyer</span></td>
                                    <td><span><b>Phone Number</b> </span> <br /><span> {getUser?.data?.phoneNumber}</span></td>
                                    <td><span><b>Supreme Court No.</b> </span> <br /><span> {getUser?.data?.supremeCourtNumber}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td><span><b>Email Address</b> </span> <br /><span>{getUser?.data?.email}</span></td>
                                    <td><span><b>Location</b> </span> <br /><span> {getUser?.data?.Location}</span></td>
                                    <td><span><b>MBA Number</b> </span> <br /><span> {getUser?.data?.nbaNumber}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td colSpan={2}><span><b>About {getUser?.data?.firstName} {getUser?.data?.lastName}</b> </span> <br /><span>{getUser?.data?.BriefBio}</span></td>
                                </tr>
                                <tr><br /></tr>
                                <tr><td colSpan={2}></td></tr>
                            </Table>
                        </div>
                    </div>
                </div>
            </>
                :
                <>
                    <div className="card px-5 mt-3">
                        <div className="row mt-5 mb-5">
                            <div className="col-md-4">
                                <div className="text-center border rounded">
                                    {getUser?.data?.ImgLocation === null || getUser?.data?.ImgLocation === undefined ? <FaUserCircle style={{ fontSize: "200px" }} /> : <img src={`/legal-tech-uploads/${getUser?.data?.ImgLocation}`} width="100%" alt="" />}
                                    <Form.Control type="file" onChange={(e) => setUpdateLogo(e.target.files[0])} className="mb-2" style={{ width: "200px", margin: "auto" }}></Form.Control>
                                    <button type="submit" className="primary-btn" onClick={() => handleUpdateLogo()}><AiOutlineEdit /> Update Photo</button></div>


                                {/* <img src={`/legal-tech-uploads/${getUser?.data?.ImgLocation}`} height="100%" width="100%" alt="" />
                                {getUser?.data?.isVerify == true && <BsPatchCheck size={50} className="text-primary" style={{ float: "right", margin: "-80px 30px 0px 0px" }} />} */}
                            </div>
                            <div className="col-md-8 px-5">
                                <Table responsive className="table" width="100%">
                                    <tr>
                                        <td><span><b>{getUser?.data?.companyName}</b></span> <br /><span> Dispute Lawyer</span></td>
                                        <td><span><b>Phone Number</b> </span> <br /><span> {getUser?.data?.phoneNumber}</span></td>
                                        <td><span><b>Responsible Person</b> </span> <br /><span> {getUser?.data?.responsiblePerson}</span></td>
                                    </tr>
                                    <tr><br /><br /></tr>
                                    <tr>
                                        <td><span><b>Email Address</b> </span> <br /><span>{getUser?.data?.emailAddress}</span></td>
                                        <td><span><b>Location</b> </span> <br /><span> {getUser?.data?.Location}</span></td>
                                        <td><span><b>Designation</b> </span> <br /><span> {getUser?.data?.areaOfPractice}</span></td>
                                    </tr>
                                    <tr><br /><br /></tr>
                                    <tr>
                                        <td colSpan={2}><span><b>About {getUser?.data?.firstName} {getUser?.data?.lastName}</b> </span> <br /><span>{getUser?.data?.BriefBio}</span></td>
                                    </tr>
                                    <tr><br /></tr>
                                    <tr><td colSpan={2}></td></tr>
                                </Table>
                            </div>
                        </div>
                    </div>
                </>}
            <div className="d-flex justify-content-between">
                <button type="button" onClick={() => setShow(true)} className="primary-btn px-5 mt-3"> Invite a colleague {loading && <Spinner animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} />}</button>
                <button type="button" onClick={() => navigate('/profile')} className="primary-btn px-5 mt-3"> Edit Profile {loading && <Spinner animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} />}</button>
            </div>

            <Modal size="md" centered show={show} onHide={() => setShow(false)} style={{ backgroundColor: "#0A3B81F7" }}>
                <Modal.Body className={styles.invite}>
                    <div style={{ display: 'block', textAlign: 'right', marginRight: '10px' }}><MdClose style={{ cursor: "pointer" }} onClick={() => setShow(false)} /></div>
                    <div className="pb-2">
                        <Form action="" onSubmit={handleInvite} className="p-4">
                            <h5 align="center">Enter your colleague details to proceed.</h5><br />
                            <div className="mb-4">
                                <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Full Name:</Form.Label>
                                <Form.Control type="text" required onChange={(e) => setInvite({ ...invite, name: e.target.value })} placeholder="John Mark" className={styles.inputField}></Form.Control>
                            </div>
                            <div className="mb-4">
                                <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Email Address:</Form.Label>
                                <Form.Control type="email" required onChange={(e) => setInvite({ ...invite, email: e.target.value })} placeholder="johnmark@yahoo.com" className={styles.inputField}></Form.Control>
                            </div>
                            <div className="text-center"><button type="submit" className="primary-btn">Submit &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button></div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </Sidebar >
    )
}

export default DashboardPage;
import React from "react";
import Header from "../components/Header";
import styles from '../styles/AboutPage.module.css';
import about from '../images/Rectangle 37.png';
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";



import FadedLogo from '../assets/faded-logo.png';
import Team from '../assets/business-team.png';
import Boxcard1 from '../assets/boxcard1.png';
import Boxcard2 from '../assets/serious-african.png';

const ContactPage = () => {

    document.title = "Contact Us - Legal Fuse";

    return (
        <main>
            <Header />
            <section className="pb-5 mb-5">
                <div className="container">
                    <div className="row mt-5 pb-5">
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-4 m-auto pb-5">
                            <div className="lh-lg" style={{ zIndex: "2", position: "relative" }}>
                                <h3 className="fw-bold">Contact Us</h3>
                                <p>If you need any assistance or want to ask questions about how LegalFuse works.You can reach us through the provided email or fill the form.</p>
                                <Link to="mailto:info@legalfuse.com">info@legalfuse.com</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src={FadedLogo} width="100%" style={{ marginLeft: "-100px", zIndex: "1", position: "relative" }} />
                        </div>
                        <div className="col-md-2">
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "-100px", zIndex: "3", position: "relative" }}>
                        <div className="col-md-4 px-3">
                            <img src={Boxcard1} width="100%" />
                        </div>
                        <div className="col-md-8 pt-1">
                            <img src={Team} width="100%" />
                        </div>
                    </div>

                    <div className="row pt-5 pb-5">
                        <div className="col-md-4">
                            <div style={{ backgroundColor: "#052B61", borderRadius: "20px", height: "100%", padding: "50px 0px" }}>
                                <img src={FadedLogo} width="100%" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Form className="px-4 py-5 text-center" style={{ backgroundColor: "#D5E6FF", height: "100%",borderRadius: "20px" }}>
                                <h5>Send a <b className="fw-bold">message</b></h5>
                                <Form.Control type="text" className="mt-4 px-3 py-3" placeholder="Your name"/>
                                <Form.Control type="email" className="mt-4 px-3 py-3" placeholder="Email"/>
                                <Form.Control as="textarea" rows={5} className="mt-4 px-3 py-2" />
                                <button type="submit" className="primary-btn-1 my-3 py-3 px-3 w-100"> Search</button>
                            </Form>
                        </div>
                        <div className="col-md-4">
                            <img src={Boxcard2} width="100%" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    )
}

export default ContactPage;
import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import about from '../../images/Rectangle 37.png';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "../../styles/Blog.module.css";

const Blog3 = () => {

    document.title = "Contact Us - Legal Fuse";

    return (
        <section>
            <Header />
            <div className="container mt-5 mb-5 pb-5">
                <div className="row col-md-10 m-auto">
                    <h3 className={styles.title}>Robotic lawyer: A threat to conventional practice?</h3>
                    <img src="https://guardian.ng/wp-content/uploads/2021/06/AI.jpg" className="mb-3 mt-3" />
                    <p><b>AI and the robot revolution PHOTO Courtesy: theconversation.com</b></p>
                    <p>A lawyer’s daily tasks include researching cases, writing briefs, and giving advice to clients. These tasks are arguably being handled with greater speed with the use of technology today.</p>
                    <p> DoNotPay Inc., in America, invented a robot, known as Artificial Intelligence (AI) to provide legal services to people as may be required. The effect of the invention is being felt all over the world as the possibility of putting lawyers out of business arose.</p>
                    <p> The technological inroads into the legal industry like most professions have assumed a different dimension where robots have intricate responsibilities. Today, AI software systems – a computer programme that can update and “think” by themselves – are increasingly being used by the legal community.</p>
                    <p> While this may be bad news for lawyers, it could be great for their future clients – particularly those who have trouble affording legal assistance. The robot helps users draft legal letters. You tell its chatbot what your problem is, and it will suggest what it thinks is the best legal language to use and the way out.</p>
                    <p> With this improvement in 21st century lawyering, human lawyers are afraid that AI is infringing on their territory, and rightly so. However, there are lawyers who are pleased with it and believe that the programme may be used to quickly browse and sort through enormous amounts of case materials.</p>
                    <p> Robot law is an emerging specialist field and enthusiastic lawyers focus on the laws of robots or robotics with the anticipation that it would, notwithstanding, enhance their jobs. To fully deploy this system in Nigeria, a legal framework is required to deal with it.</p>
                    <p> According to analysts, legal practice in Nigeria recognises natural persons and juristic persons (like companies or trusts). Now, to accommodate the third entity, which is a new kind of person – the robot (or robot person), it will require legislation.</p>
                    <p> Stakeholders argued that since there are laws that apply to people and companies, which recognise that companies are separate legal entities that can act on their own, there is a need that the same legal framework for robots (maybe called the Robots Act) be enacted to give its operation a stamp of legality.</p>
                    <p> When these laws are in place, they argued, it would enhance the operation of robotic lawyers as an entity to further speed up legal processes and aid challenges of legal services.</p>
                    <p> Christian Oti, an Abuja based lawyer is not comfortable with the technology. According to him, the said robot lawyer must also go through the educational training and get to be called to the Nigerian bar, if it must be allowed to operate in the country.</p>
                    <p> This, notwithstanding, robotic lawyer, he said may be used for document separations, case file management, legal research and possibly client-witness interview.</p>
                    <p> The lawyer stressed robots cannot be a threat to real human lawyers. “A machine is limited in the practice of law, in my own view. Legal practice goes beyond technical legal knowledge, it encompasses social, emotional and creative solutions too,” he pointed out.</p>
                    <p> According to him, legal drafting and sundry paper works are not the principal functions expected of lawyers alone. He believes that it goes with emotional attachment to resolving legal challenges of clients.</p>
                    <p> For Emmanuel Ekwe, also a lawyer, there’s no place for robotic lawyering under Nigeria’s legal system. He, therefore, suggested that it could best serve as a research assistant. “A robot cannot pass through the Nigerian Law School,” he declared.</p>
                    <p> The lawyer stressed that Nigeria’s legal system is not yet structured to accommodate the use of such AI in place of humans. Another lawyer, Akintayo Balogun said the advent of robots that have the ability to do the work of a legal practitioner has raised several eyebrows regarding the propriety or otherwise of it being used in Nigeria.</p>
                    <p> According to him, the ability of the robot to perform as a regular legal practitioner has been called to question. He insisted that in Nigeria, a robot cannot practice as a legal practitioner until it fulfills the conditions as provided for in Section 4 of the Legal Practitioners Act.</p>
                    <p> “This fact remains unchanged. However, there are some salient points that must be noted. Firstly, in the report received from the inventors of the robot, the said robot is designed to listen to adverse arguments in court and to respond to the owner via an earpiece.</p>
                    <p> “Secondly, section 36 of the 1999 Constitution of Nigeria (as altered), provides that every person is allowed to defend himself in person or through a legal practitioner.</p>
                    <p> “With this constitutional provision and the advent of this robot, the implication is that a non-legal practitioner can decide to acquire a robot and go to court in the company of his robot to defend himself personally.</p>
                    <p> “All he needs is to have his robot in court, listening to arguments and issues raised in court and communicating the required responses to its owner via the earpiece,” he lamented.</p>
                    <p> Balogun argued that it might pose a challenge to the practice of law in the country, if the robot is acquired by non-legal practitioners and seen to be highly effective in communicating the correct position of the law to its owner at the needed time.

                        His words: “The need for legal practitioners might dwindle if the robot has the ability to do everything that a lawyer can do. Also, non-legal practitioners might hide under section 36 of the Constitution to begin to do all that a legal practitioner would have done with the help of their robots.</p>
                    <p> “Where non-legal practitioners can append their signatures on documents, do research, receive legal advice from their robots, the practice of law for legal practitioners might be jeopardised.”</p>
                    <p> Salvanus Maliki, a lawyer, wondered if robotic lawyers could make appearances in court and do all that a legal practitioner can do in Nigeria? He stated that robotic lawyers cannot practice as a regular lawyer in Nigeria because the requirement of who can practice as a legal practitioner is a function of the law and is sacrosanct.</p>
                    <p> Outlining the qualification necessary to become a lawyer in Nigeria, he cited Section 4 of the Legal Practitioners Act, which provides that the person should be a citizen of Nigeria and should produce a qualifying certificate to the Benchers showing that he has attended the Nigerian law School and has met all the requirements to be called to the Bar.</p>
                    <p> Continuing, Maliki said the law provides that the fellow must also show the Benchers that he is a person of good character, before the body of Benchers issue him a certificate of Call to Bar.</p>
                    <p> He said until the robot is able to fulfill the above, it cannot practice as a legal practitioner in Nigeria, so the threat is unreal. Again, he argued that the robot cannot append its signature on any document that requires the signature of a lawyer.</p>
                    <p> “The best the robot can do is to be a research assistant, offer advice, draft documents, assist the lawyer in other ways, etc. The robot can only operate within the confines of an office space or to sit in court and assist with legal research. Outside this, its activities are worthless before the law,” he argued. He held that robot lawyers are not a threat to Nigerian lawyers, adding that it can only come to assist lawyers in the performance of their duties.</p>
                    <p> “A lawyer can decide to enter into the court with a robot, but the best the robot can do is to assist in research and other table work that needs to be done in the court. Outside the court, the robot can work as a research assistant or a draftsman, if programmed to do that.</p>
                    <p> “Already, the world’s first robot lawyer, developed by a startup, DoNotPay, has been sued over allegations of fraud by appearing in the courtroom without a law license to practice in America.</p>
                    <p> “According to the Washington Examiner, DoNotPay’s Chief Executive Officer (CEO), Joshua Browder and his company have defended Artificial Intelligence to take up the law profession. “However, minutes submitted to the court on March 3 said the first robot lawyer’s appearance might also be his last,” he declared.
                    </p>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default Blog3;
import React from 'react';
import WhiteLogo from '../assets/white-logo.svg';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';


const Footer = () => {

    return (
        <section className="footer-bg pt-5 text-white">
            <div className="container justify-content-between mb-3 pt-5 pb-5">
                <div className='row'>
                    <div className="col-md-4 pe-3">
                        <img src={WhiteLogo} width='200px' alt="" />
                        <p className="mt-3 lh-base">Legal Fuse is a fast growing online law information sites and has grown to be a top-most popular legal resource on the internet. It includes extensive directories of law firms and lawyers in Nigeria at the moment.</p>
                    </div>
                    <div className="col-md-1 mt-3"></div>
                    <div className="col-md-2 mt-3">
                        <h5>COMPANY</h5>
                        <ul className="list-unstyled mt-4">
                            <li className="list-group-item lh-lg">
                                <a className="text-white text-decoration-none mb-2" href='/'>Home</a>
                            </li>
                            <li className="list-group-item lh-lg">
                                <a className="text-white text-decoration-none mb-2" href='/about-us'>About Us</a>
                            </li>
                            <li className="list-group-item lh-lg">
                                <a className="text-white text-decoration-none mb-2" href='/contact-us'>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2 mt-3">
                        <h5>QUICK LINK</h5>
                        <ul className="list-unstyled mt-4">
                            <li className="list-group-item lh-lg">
                                <a className="text-white text-decoration-none mb-2" href='/search-page'>Find a lawyer</a>
                            </li>
                            <li className="list-group-item lh-lg">
                                <a className="text-white text-decoration-none mb-2" href='/signup-lawyer'>Sign Up Now</a>
                            </li>
                            <li className="list-group-item lh-lg">
                                <a className="text-white text-decoration-none mb-2" href='mailto:info@legalfuse.com'>Email Us: <br />info@legalfuse.com</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3 mt-3">
                        <p className='ps-3'>Stay industry connected with our monthly newsletter</p>
                    </div>
                </div>
            </div>
            <hr></hr>

            <div className="container copyright d-flex justify-content-between pt-2 pb-3">
                    <span className="text-light">Copyright &copy; {new Date().getFullYear()} Legal Fuse</span>
                    <div>
                        <AiOutlineInstagram size={25} className='border rounded-circle p-1 ms-3'/> 
                        <AiOutlineTwitter size={25} className='border rounded-circle p-1 ms-3'/> 
                        <FaFacebookF size={25} className='border rounded-circle p-1 ms-3'/> 
                        <FaLinkedinIn size={25} className='border rounded-circle p-1 ms-3'/></div>
                </div>
        </section>
    )
}

export default Footer;
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import styles from "../../styles/SettingsPage.module.css";
import logo from '../../images/legal-fuse.png';
import { MdStar } from "react-icons/md";
import routes from "../../routes";
import { Container, Form, Modal, Nav, Navbar, NavDropdown, Spinner } from "react-bootstrap";
import { FaEye, FaEyeSlash, FaUserCircle } from "react-icons/fa";
import client from "../../api/client";
import Sidebar from "../../components/Layout";
import { BsChevronRight } from "react-icons/bs";


const SettingsPage = () => {

    document.title = "Account Dashboard - Legal Fuse";

    const navigate = useNavigate();

    const location = useLocation();
    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const getUser = JSON.parse(localStorage.getItem("user"));

    const authenticate = () => {
        if (localStorage.getItem("user") === null || localStorage.getItem("user") === undefined) {
            navigate('/');
        }
    }
    useEffect(() => {
        authenticate();
    }, [getUser]);


    const [passwordType0, setPasswordType0] = useState("password");
    const [passwordInput0, setPasswordInput0] = useState("");
    const handlePasswordChange0 = (evnt) => {
        setPasswordInput0(evnt.target.value);
    }
    const togglePassword0 = () => {
        if (passwordType0 === "password") {
            setPasswordType0("text")
            return;
        }
        setPasswordType0("password")
    }

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordInput1, setPasswordInput1] = useState("");
    const handlePasswordChange1 = (evnt) => {
        setPasswordInput1(evnt.target.value);
    }
    const togglePassword1 = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }

    const handleLogout = () => {
        if (localStorage.getItem("user") !== null && localStorage.getItem("user") !== undefined) {
            window.localStorage.removeItem("user");
        }
    }

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (getUser?.data?.companyName !== null && getUser?.data?.companyName !== undefined) {
            const input = {
                "type": "Law Firm",
                "newPassword": passwordInput,
                "oldPassword": passwordInput0,
                "email": getUser?.data?.emailAddress
            };
            const res = await client.post('/firm/authentication/changePassword', input);
            if (res.data.statusCode === 200) {
                alert("Password Updated Successfully");
            }
            else {
                alert(res.data.message);
            }
        }
        if (getUser?.data?.companyName === null || getUser?.data?.companyName === undefined) {
            const input = {
                "type": "Lawyer",
                "newPassword": passwordInput,
                "oldPassword": passwordInput0,
                "email": getUser?.data?.email
            };
            const res = await client.post('/lawyer/authentication/changePassword', input);
            if (res.data.statusCode === 200) {
                alert("Password Updated Successfully");
            }
            else {
                alert(res.data.message);
            }
        }
    }


    return (
        <Sidebar pageTitle={"User Profile"}>
            <div className="my-4">
                <h5><b>Change your password</b> </h5>
                <Form action="" onSubmit={handleUpdatePassword}>
                    <div className="row col-md-4 mt-3">
                        <div className="col-md-12">
                            <Form.Control type={passwordType0} required onChange={handlePasswordChange0} value={passwordInput0} className="searchInput" placeholder="Current Password"></Form.Control><div style={{ float: "right", margin: "-70px 20px 0px 0px" }} >{passwordType0 === "password" ? <FaEyeSlash size={20} onClick={() => togglePassword0()} /> : <FaEye size={20} onClick={() => togglePassword0()} />}</div>
                        </div>
                        <div className="col-md-12">
                            <Form.Control type={passwordType} required onChange={handlePasswordChange} value={passwordInput} className="searchInput" placeholder="New Password"></Form.Control><div style={{ float: "right", margin: "-70px 20px 0px 0px" }} >{passwordType === "password" ? <FaEyeSlash size={20} onClick={() => togglePassword()} /> : <FaEye size={20} onClick={() => togglePassword()} />}</div>
                        </div>
                        <div className="col-md-12">
                            {passwordInput !== passwordInput1 ? <span className="">Password does not match.</span> : passwordInput.length > 0 && passwordInput1.length > 0 && passwordInput === passwordInput1 ? <span className="text-success">Password match.</span> : <></>}
                            <Form.Control type={passwordType1} required onChange={handlePasswordChange1} value={passwordInput1} className="searchInput" placeholder="Confirm Password"></Form.Control><div style={{ float: "right", margin: "-70px 20px 0px 0px" }} >{passwordType1 === "password" ? <FaEyeSlash size={20} onClick={() => togglePassword1()} /> : <FaEye size={20} onClick={() => togglePassword1()} />}</div>
                        </div>
                        <div className="text-end">
                            <button type="submit" className="primary-btn">Update &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button>
                        </div>
                    </div>
                </Form>
                <Modal size="sm" centered show={show} onHide={() => setShow(false)}>
                    <Modal.Body className="text-center">
                        <Spinner animation="border" variant="primary" style={{ height: "100px", width: "100px", fontSize: "30px" }} />
                    </Modal.Body>
                </Modal>
            </div>
        </Sidebar>
    )
}

export default SettingsPage;
import React, { useEffect, useState } from "react";
import client from "../api/client";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../styles/Header.css';
import FindLawyer from '../assets/vector123.svg';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Modal, NavDropdown } from "react-bootstrap";
import { MdOutlineArrowRightAlt, MdClose } from "react-icons/md";
import Alert from "react-bootstrap/Alert";
import Spinner from 'react-bootstrap/Spinner';
import { FaEye, FaEyeSlash, FaUserAlt } from "react-icons/fa";
import { AiOutlineCloseCircle, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsCheckCircleFill, BsChevronRight } from "react-icons/bs";
import Select from "react-select";
import { CgArrowLongLeft } from "react-icons/cg";
import SuccessIcon from '../assets/success.png';


const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showLogin, setShowLogin] = useState(false);
    const [show1, setShow1] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [signUp, setSignUp] = useState("lawyer");
    const [activeTab, setActiveTab] = useState("first");
    const [activeStep, setActiveStep] = useState("first");
    const [checkPassword, setCheckPassword] = useState("password");
    const [signIn, setSignIn] = useState();
    const [errorLogin, setErrorLogin] = useState();
    const [forgotStatus, setForgotStatus] = useState();

    const [signUpSuccess, setSignUpSuccess] = useState(false);

    const [signUpLawyer, setSignUpLawyer] = useState();
    const [signUpLawFirm, setSignUpLawFirm] = useState();

    const [forgot, setForgot] = useState();

    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await client.post('/firm/authentication/login', signIn);
        if (res.data.statusCode === 200) {
            window.localStorage.setItem("user", JSON.stringify(res.data));
            setLoading(false);
            navigate('/dashboard');
        }
        else {
            setLoading(false);
            setErrorLogin(
                <Alert variant="danger">
                    {res.data.message}
                </Alert>
            );
        }
    };

    const handleForgot = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await client.post('/firm/authentication/forgot', forgot);
        if (res.data.statusCode === 200) {
            setLoading(false);
            setForgotStatus(
                <Alert variant="success">
                    {res.data.message}. Kindly check your email to proceed
                </Alert>
            )
        }
        else {
            setLoading(false);
            setForgotStatus(
                <Alert variant="danger">
                    {res.data.message}
                </Alert>
            )
        }
    }

    const handleLogout = () => {
        if (localStorage.getItem("user") !== null && localStorage.getItem("user") !== undefined) {
            window.localStorage.removeItem("user");
            window.location.reload(false);
        }
    }

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }


    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordInput1, setPasswordInput1] = useState("");
    const handlePasswordChange1 = (evnt) => {
        setPasswordInput1(evnt.target.value);
    }
    const togglePassword1 = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }

    const [inputList, setInputList] = useState([{ "name": "", "designation": "" }]);
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { "name": "", "designation": "" }]);
    };

    //////////////////////// Handles for Sign Up Lawyers //////////////////////////
    const handleNext = async (e) => {
        e.preventDefault();
        const MAX_FILE_SIZE = 1024 // 1MB
        const uploadFileSize = signUpLawyer.file.size / 1024;
        if (uploadFileSize > MAX_FILE_SIZE) {

        }
        setActiveStep("second");
    }
    const handleNext1 = async (e) => {
        e.preventDefault();
        const AreaOfPractice = skillOptions1.map(list => (list.value));
        const AreaOfExpertise = skillOptions.map(list => (list.value));
        setSignUpLawyer({ ...signUpLawyer, AreaOfPractice: AreaOfPractice, AreaOfExpertise: AreaOfExpertise });
        setActiveStep("third");
    }
    const handleNext2 = async (e) => {
        e.preventDefault();
        setLoading(true);
        const input = ({ ...signUpLawyer, password: passwordInput });
        const formData = new FormData();
        formData.append("email", signUpLawyer.email);
        formData.append("password", passwordInput);
        formData.append("firstName", signUpLawyer.firstName);
        formData.append("lastName", signUpLawyer.lastName);
        formData.append("addrress", signUpLawyer.addrress);
        formData.append("phoneNumber", signUpLawyer.phoneNumber);
        formData.append("nbaNumber", signUpLawyer.nbaNumber);
        formData.append("supremeCourtNumber", signUpLawyer.supremeCourtNumber);
        formData.append("AreaOfPractice", signUpLawyer.AreaOfPractice);
        formData.append("AreaOfExpertise", signUpLawyer.AreaOfExpertise);
        formData.append("BriefBio", signUpLawyer.BriefBio);
        formData.append("Location", signUpLawyer.Location);
        formData.append("file", signUpLawyer.file);

        const res = await client.post('/lawyer/authentication/registerUser', formData);
        if (res.data.statusCode === 200) {
            setShowSignup(false);
            setActiveStep("first")
            setSignUpSuccess(true)
            setLoading(false);
        }
        else {
            setLoading(false);
            alert(res.data.message);
        }
    }
    /////////////////////////////////////////////////////////////////////////////////

    //////////////////////// Handles for Sign Up Law Firms //////////////////////////

    const handleNextLawfirm = async (e) => {
        e.preventDefault();
        setActiveStep("second");
    }
    const handleNextLawfirm1 = async (e) => {
        e.preventDefault();
        const AreaOfPractice = skillOptions.map(list => (list.value));
        setSignUpLawFirm({ ...signUpLawFirm, areaOfPractice: AreaOfPractice, responsiblePerson: JSON.stringify(inputList) });
        setActiveStep("third");
    }
    const handleNextLawfirm2 = async (e) => {
        e.preventDefault();
        setLoading(true);
        const input = ({ ...signUpLawFirm, password: passwordInput });
        const formData = new FormData();
        formData.append("emailAddress", signUpLawFirm.emailAddress);
        formData.append("password", passwordInput);
        formData.append("companyName", signUpLawFirm.companyName);
        formData.append("website", signUpLawFirm.website);
        formData.append("address", signUpLawFirm.address);
        formData.append("phoneNumber", signUpLawFirm.phoneNumber);
        formData.append("responsiblePerson", signUpLawFirm.responsiblePerson);
        formData.append("areaOfPractice", signUpLawFirm.areaOfPractice);
        formData.append("BriefBio", signUpLawFirm.BriefBio);
        formData.append("Location", signUpLawFirm.Location);
        formData.append("file", signUpLawFirm.file);

        const res = await client.post('/firm/authentication/registerFirm', formData);
        if (res.data.statusCode === 200) {
            setShowSignup(false);
            setActiveStep("first")
            setSignUpSuccess(true)
            setLoading(false);
        }
        else {
            setLoading(false);
            alert(res.data.message);
        }
    }


    ///////////////////////////////////////////////////////////////////////////

    const [skillOptions, setSkillOptions] = useState(null);
    const [skillOptions1, setSkillOptions1] = useState(null);

    const options = [
        { value: 'Banking', label: 'Banking' },
        { value: 'Capital Markets (Debt)', label: 'Capital Markets (Debt)' },
        { value: 'Capital Markets (Equity)', label: 'Capital Markets (Equity)' },
        { value: 'Election Petition', label: 'Election Petition' },
        { value: 'M&A', label: 'M&A' },
        { value: 'Project Developmet (Energy)', label: 'Project Developmet (Energy)' },
        { value: 'Project Development (Infrastruture)', label: 'Project Development (Infrastruture)' },
        { value: 'Restructuring and Insolvency', label: 'Restructuring and Insolvency' }
    ];

    const options1 = [
        { value: 'Corporate Commercial', label: 'Corporate Commercial' },
        { value: 'Arbitration', label: 'Arbitration' },
        { value: 'Dispute', label: 'Dispute' },
        { value: 'Bankruptcy', label: 'Bankruptcy' },
        { value: 'Business Law', label: 'Business Law' },
        { value: 'Car Accident', label: 'Car Accident' },
        { value: 'Criminal Defense', label: 'Criminal Defense' },
        { value: 'Employment', label: 'Employment' },
        { value: 'Estate Planning', label: 'Estate Planning' },
        { value: 'Family Law', label: 'Family Law' },
        { value: 'Government', label: 'Government' },
        { value: 'Health Care & Social', label: 'Health Care & Social' },
        { value: 'Immigration', label: 'Immigration' },
        { value: 'Industry', label: 'Industry' },
        { value: 'Intellectual Property', label: 'Intellectual Property' },
        { value: 'International Law', label: 'International Law' },
        { value: 'Litigation', label: 'Litigation' },
        { value: 'Medical Malpractice', label: 'Medical Malpractice' },
        { value: 'Personal Injury', label: 'Personal Injury' },
        { value: 'Real Estate', label: 'Real Estate' },
        { value: 'Tax', label: 'Tax' }
    ];


    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="light" className="mt-2 mb-2">
                <Container>
                    <Navbar.Brand href="/"><img src={FindLawyer} width="170px" alt="" /> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav className="m-0 p-0">
                            <Nav.Link className={location.pathname === "/" ? "activeMenuLink" : "menuLink"} href="/">Home</Nav.Link>
                            <Nav.Link className={location.pathname === "/about-us" ? "activeMenuLink" : "menuLink"} href="/about-us">About us</Nav.Link>
                            <Nav.Link className={location.pathname === "/contact-us" ? "activeMenuLink" : "menuLink"} href="/contact-us">Contact us</Nav.Link>
                            <Nav.Link className="menuLink"></Nav.Link>
                            {JSON.parse(localStorage.getItem("user")) === null || JSON.parse(localStorage.getItem("user")) === undefined ? <Nav.Link href="javascript:void(0)"> <button onClick={() => setShowLogin(true)} className="outline-primary-btn m-0">Get Started</button></Nav.Link> :
                                <NavDropdown title={<span><FaUserAlt /> Account</span>} id="nav-dropdown">
                                    <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                                </NavDropdown>}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Modal size="md" centered show={showLogin} className="loginBG" onHide={() => { setActiveTab("first"); setShowLogin(false) }} style={{ backgroundColor: "#0A3B81F7" }}>
                <Modal.Body style={{ backgroundColor: "#1F4F92", border: "#FFFFFF solid 1px", borderRadius: "20px" }}>
                    <div className="text-end text-white"><AiOutlineCloseCircle onClick={() => { setActiveTab("first"); setShowLogin(false) }} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    {activeTab == "first" && <div className="pb-2">
                        <Form action="" onSubmit={handleLogin} className="px-4 py-4 text-white">
                            <h3 align="center">Welcome Back</h3>
                            <h4 align="center">Login <b>Here</b></h4>
                            <p>{errorLogin}</p>

                            <Form.Select required onChange={(e) => setSignIn({ ...signIn, accountType: e.target.value })} className="searchInput mt-5">
                                <option>Select your option</option>
                                <option value="Lawyer">Lawyer</option>
                                <option value="Law Firm">Law Firm</option>
                            </Form.Select>

                            <Form.Control type="email" className="searchInput" onChange={(e) => setSignIn({ ...signIn, email: e.target.value })} placeholder="johnmark@yahoo.com"></Form.Control>

                            <div className="d-flex">
                                <Form.Control type={checkPassword} required onChange={(e) => setSignIn({ ...signIn, password: e.target.value })} placeholder="***********" className="searchInput mb-2"></Form.Control>
                                <div style={{ margin: "15px -50px" }}>{checkPassword == "password" ? <AiOutlineEyeInvisible size={25} onClick={() => setCheckPassword("text")} className="text-dark" /> : <AiOutlineEye size={25} onClick={() => setCheckPassword("password")} className="text-dark" />}</div></div>

                            <div className="text-left">Forgot password <Link className="text-white" onClick={() => setActiveTab("second")}>Click here</Link></div>


                            <div className="text-center"><button type="submit" className="primary-btn px-5 py-3 mt-4">Login &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button></div>

                            <div className="text-center signUptext fs-5 mt-4 mb-0">Don't have an Account? <Link onClick={() => { setShowLogin(false); setShowSignup(true) }} className="text-white signUptext">Sign up</Link></div>

                        </Form>
                    </div>}

                    {activeTab == "second" && <div className="pb-2">
                        <Form action="" onSubmit={handleForgot} className="px-4 py-4 text-white">
                            <h3 align="center">Forgot Password</h3>
                            <h5 align="center">Enter your details to proceed.</h5>
                            <p>{forgotStatus}</p>
                            <Form.Control type="email" required onChange={(e) => setForgot({ ...forgot, email: e.target.value })} placeholder="johnmark@yahoo.com" className="searchInput mb-4 mt-5"></Form.Control>
                            <Form.Select required onChange={(e) => setForgot({ ...forgot, type: e.target.value })} className="searchInput mb-2">
                                <option>Select Account Type (Lawyer OR Law Firm)</option>
                                <option value="Lawyer">Lawyer</option>
                                <option value="Law Firm">Law Firm</option>
                            </Form.Select>
                            <div className="text-center"><button type="submit" className="primary-btn px-5 py-3 mt-5">Submit &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button></div>
                        </Form>
                        <div className="text-center text-white my-3">Back to login <Link className="text-white" onClick={() => setActiveTab("first")}>Click here</Link></div>
                    </div>}
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered show={showSignup} onHide={() => setShowSignup(false)} className="signUpBG bg-dark ms-auto">
                <Modal.Body className="text-right">
                    <div className="text-end text-white"><AiOutlineCloseCircle onClick={() => setShowSignup(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    <div className="px-4 pb-5">
                        <h5>Register a new account</h5>
                        <hr /><br />
                        <p>Select who you are</p>
                        <div className="bg-light p-2" style={{ width: "fit-content", borderRadius: "30px" }}>
                            <button onClick={() => {setSignUp("lawyer"); setActiveStep("first")}} className={signUp == "lawfirm" ? "blank-btn px-5 me-2" : "primary-btn px-5 me-2"}>&nbsp;&nbsp; Lawyer &nbsp;&nbsp;</button>
                            <button onClick={() => {setSignUp("lawfirm"); setActiveStep("first")}} className={signUp == "lawyer" ? "blank-btn px-5 me-2" : "primary-btn px-5 me-2"}>&nbsp;&nbsp; Law Firm &nbsp;&nbsp;</button>
                        </div>
                        {signUp == "lawyer" && <>
                            <div className="d-flex gap-5 my-4 signUptabTitle">
                                <span className={activeStep == "first" && "active"}>Personal Details {activeStep !== "first" && <BsCheckCircleFill />}</span>
                                <span className={activeStep == "second" && "active"}>Professional Details {activeStep == "third" && <BsCheckCircleFill />}</span>
                                <span className={activeStep == "third" && "active"}>Create Password {activeStep == "complete" && <BsCheckCircleFill />}</span>
                            </div>

                            {activeStep == "first" &&
                                <Form action="" onSubmit={handleNext} encType="multipart/form-data">
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="First Name" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, firstName: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="Last Name" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, lastName: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Form.Control type="email" required placeholder="Email Address" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, email: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="Phone Number" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, phoneNumber: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Form.Control type="file" required accept="image/x-png,image/jpg,image/jpeg" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, file: e.target.files[0] })} className="bg-light px-3 py-2"></Form.Control><label style={{ fontSize: "10px" }}>Only JPG and PNG files are allowed for upload. Max size 10mb</label>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Select name="locations" id="locations" required className="bg-light px-3 py-2" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, Location: e.target.value })}>
                                                <option value="">Select location</option>
                                                <option value="Abia">Abia</option>
                                                <option value="Adamawa">Adamawa</option>
                                                <option value="Akwa Ibom">Akwa Ibom</option>
                                                <option value="Anambra">Anambra</option>
                                                <option value="Bauchi">Bauchi</option>
                                                <option value="Bayelsa">Bayelsa</option>
                                                <option value="Benue">Benue</option>
                                                <option value="Borno">Borno</option>
                                                <option value="Cross River">Cross River</option>
                                                <option value="Delta">Delta</option>
                                                <option value="Ebonyi">Ebonyi</option>
                                                <option value="Edo">Edo</option>
                                                <option value="Ekiti">Ekiti</option>
                                                <option value="Enugu">Enugu</option>
                                                <option value="FCT">Federal Capital Territory</option>
                                                <option value="Gombe">Gombe</option>
                                                <option value="Imo">Imo</option>
                                                <option value="Jigawa">Jigawa</option>
                                                <option value="Kaduna">Kaduna</option>
                                                <option value="Kano">Kano</option>
                                                <option value="Katsina">Katsina</option>
                                                <option value="Kebbi">Kebbi</option>
                                                <option value="Kogi">Kogi</option>
                                                <option value="Kwara">Kwara</option>
                                                <option value="Lagos">Lagos</option>
                                                <option value="Nasarawa">Nasarawa</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Ogun">Ogun</option>
                                                <option value="Ondo">Ondo</option>
                                                <option value="Osun">Osun</option>
                                                <option value="Oyo">Oyo</option>
                                                <option value="Plateau">Plateau</option>
                                                <option value="Rivers">Rivers</option>
                                                <option value="Sokoto">Sokoto</option>
                                                <option value="Taraba">Taraba</option>
                                                <option value="Yobe">Yobe</option>
                                                <option value="Zamfara">Zamfara</option>
                                            </Form.Select>
                                        </div>
                                        <div className="text-end mt-3"><button type="submit" className="primary-btn">Next &nbsp;&nbsp; <BsChevronRight /></button></div>
                                    </div>
                                </Form>
                            }

                            {activeStep == "second" &&
                                <Form action="" onSubmit={handleNext1}>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="NBA Number" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, nbaNumber: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="Supreme Court Number" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, supremeCourtNumber: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Select required
                                                isMulti
                                                placeholder="Area of Practice"
                                                name="skills"
                                                options={options1}
                                                onChange={setSkillOptions1}
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Select required
                                                isMulti
                                                placeholder="Area of Expertise"
                                                name="skills"
                                                options={options}
                                                onChange={setSkillOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-12">
                                            <Form.Control type="text" required placeholder="Place of work" onChange={(e) => setSignUpLawyer({ ...signUpLawyer, addrress: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4 p-0">
                                        <div className="col-md-12">
                                            <Form.Control as="textarea" maxlength="500" required placeholder="Tell us about yourself" rows={3} onChange={(e) => setSignUpLawyer({ ...signUpLawyer, BriefBio: e.target.value })} className="bg-light form-control w-100 px-3 py-2"></Form.Control>
                                        </div>
                                    </div>
                                    <div className="row mt-5 me-4">
                                        <div className="col-md-6">
                                            <button type="button" onClick={() => setActiveStep('first')} className="secondary-btn"> <CgArrowLongLeft /> Previous</button>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type="submit" className="primary-btn px-5 py-2">Next <MdOutlineArrowRightAlt /></button>
                                        </div>
                                    </div>
                                </Form>
                            }

                            {activeStep == "third" &&
                                <Form action="" onSubmit={handleNext2}>
                                    {passwordInput !== passwordInput1 ? <span className="text-danger" style={{ fontSize: "11px" }}>Password does not match.</span> : passwordInput.length > 0 && passwordInput1.length > 0 && passwordInput === passwordInput1 ? <span className="text-success" style={{ fontSize: "11px" }}>Password match.</span> : <></>}
                                    <div className="row mt-0 me-4">
                                        <div className="col-md-6">
                                            <Form.Control type={passwordType} required onChange={handlePasswordChange} value={passwordInput} className="bg-light px-3 py-2" placeholder="***************"></Form.Control><div style={{ marginTop: "-35px", marginRight: "20px", float: "right" }}>{passwordType === "password" ? <FaEyeSlash onClick={() => togglePassword()} /> : <FaEye onClick={() => togglePassword()} />}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type={passwordType1} required onChange={handlePasswordChange1} value={passwordInput1} className="bg-light px-3 py-2" placeholder="***************"></Form.Control><div style={{ marginTop: "-35px", marginRight: "20px", float: "right" }}>{passwordType1 === "password" ? <FaEyeSlash onClick={() => togglePassword1()} /> : <FaEye onClick={() => togglePassword1()} />}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-5 me-4">
                                        <div className="col-md-6">
                                            <button type="button" onClick={() => setActiveStep('second')} className="secondary-btn"> <CgArrowLongLeft /> Previous</button>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type="submit" disabled={passwordInput !== passwordInput1} className="primary-btn">Submit &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button>
                                        </div>
                                    </div>
                                </Form>
                            }
                        </>}

                        {signUp == "lawfirm" && <>
                            <div className="d-flex gap-5 my-4 signUptabTitle">
                                <span className={activeStep == "first" && "active"}>Company Details {activeStep == "second" && <BsCheckCircleFill />}</span>
                                <span className={activeStep == "second" && "active"}>Professional Details {activeStep == "third" && <BsCheckCircleFill />}</span>
                                <span className={activeStep == "third" && "active"}>Create Password {activeStep == "complete" && <BsCheckCircleFill />}</span>
                            </div>
                            {activeStep == "first" &&
                                <Form action="" onSubmit={handleNextLawfirm} encType="multipart/form-data">
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="Company Name" onChange={(e) => setSignUpLawFirm({ ...signUpLawFirm, companyName: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="Company Website" onChange={(e) => setSignUpLawFirm({ ...signUpLawFirm, website: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Form.Control type="email" required placeholder="Company Email" onChange={(e) => setSignUpLawFirm({ ...signUpLawFirm, emailAddress: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="Phone Number" onChange={(e) => setSignUpLawFirm({ ...signUpLawFirm, phoneNumber: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Form.Control type="file" required placeholder="Upload Company Logo" accept="image/x-png,image/jpg,image/jpeg" onChange={(e) => setSignUpLawFirm({ ...signUpLawFirm, file: e.target.files[0] })} className="bg-light px-3 py-2"></Form.Control>
                                            <label style={{ fontSize: "10px" }}>Maximum file size is 10mb</label>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type="text" required placeholder="Company Address" onChange={(e) => setSignUpLawFirm({ ...signUpLawFirm, address: e.target.value })} className="bg-light px-3 py-2"></Form.Control>
                                        </div>
                                        <div className="text-end mt-3"><button type="button" onClick={() => setActiveStep("second")} className="primary-btn">Next &nbsp;&nbsp; <BsChevronRight /></button></div>
                                    </div>
                                </Form>
                            }
                            {activeStep == "second" &&
                                <Form action="" onSubmit={handleNextLawfirm1}>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <Select
                                                isMulti
                                                name="skills"
                                                placeholder="Area of Practice"
                                                options={options}
                                                onChange={setSkillOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Select name="locations" id="locations" required className="bg-light px-3 py-2" onChange={(e) => setSignUpLawFirm({ ...signUpLawFirm, Location: e.target.value })}>
                                                <option value="">Select location</option>
                                                <option value="Abia">Abia</option>
                                                <option value="Adamawa">Adamawa</option>
                                                <option value="Akwa Ibom">Akwa Ibom</option>
                                                <option value="Anambra">Anambra</option>
                                                <option value="Bauchi">Bauchi</option>
                                                <option value="Bayelsa">Bayelsa</option>
                                                <option value="Benue">Benue</option>
                                                <option value="Borno">Borno</option>
                                                <option value="Cross River">Cross River</option>
                                                <option value="Delta">Delta</option>
                                                <option value="Ebonyi">Ebonyi</option>
                                                <option value="Edo">Edo</option>
                                                <option value="Ekiti">Ekiti</option>
                                                <option value="Enugu">Enugu</option>
                                                <option value="FCT">Federal Capital Territory</option>
                                                <option value="Gombe">Gombe</option>
                                                <option value="Imo">Imo</option>
                                                <option value="Jigawa">Jigawa</option>
                                                <option value="Kaduna">Kaduna</option>
                                                <option value="Kano">Kano</option>
                                                <option value="Katsina">Katsina</option>
                                                <option value="Kebbi">Kebbi</option>
                                                <option value="Kogi">Kogi</option>
                                                <option value="Kwara">Kwara</option>
                                                <option value="Lagos">Lagos</option>
                                                <option value="Nasarawa">Nasarawa</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Ogun">Ogun</option>
                                                <option value="Ondo">Ondo</option>
                                                <option value="Osun">Osun</option>
                                                <option value="Oyo">Oyo</option>
                                                <option value="Plateau">Plateau</option>
                                                <option value="Rivers">Rivers</option>
                                                <option value="Sokoto">Sokoto</option>
                                                <option value="Taraba">Taraba</option>
                                                <option value="Yobe">Yobe</option>
                                                <option value="Zamfara">Zamfara</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-12">
                                            <font style={{ fontSize: "14px", padding: "0px" }}>Responsible Person. * (Maximum of 3)</font>

                                            {inputList.map((x, i) => {
                                                return (<div className="row">
                                                    <div className="col-md-6">
                                                        <Form.Control type="text" required name="name" placeholder="Principal person's name" value={x.name}
                                                            onChange={e => handleInputChange(e, i)} className="bg-light px-3 py-2"></Form.Control>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Control type="text" required name="designation" placeholder="Designation" value={x.designation}
                                                            onChange={e => handleInputChange(e, i)} className="bg-light px-3 py-2"></Form.Control>
                                                    </div>
                                                    <div className="btn-box">
                                                        {inputList.length !== 1 && <button
                                                            className="mr10 bg-danger text-light"
                                                            onClick={() => handleRemoveClick(i)} style={{ border: "none", borderRadius: "5px", padding: "5px 10px", marginRight: "10px", marginBottom: "10px" }}> - Remove</button>}
                                                        {inputList.length < 3 && inputList.length - 1 === i && <button className="primary-btn px-3 py-2 rounded mt-1 text-light" onClick={handleAddClick}> + Add</button>}
                                                    </div>
                                                </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-12">
                                            <Form.Text as="textarea" required maxlength="500" placeholder="Tell us about yourself" rows={3} onChange={(e) => setSignUpLawFirm({ ...signUpLawFirm, BriefBio: e.target.value })} className="bg-light rounded w-100 px-3 py-2"></Form.Text>
                                        </div>
                                    </div>
                                    <div className="row mt-4 me-4">
                                        <div className="col-md-6">
                                            <button type="button" onClick={() => setActiveStep('first')} className="secondary-btn"> <CgArrowLongLeft /> Previous</button>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type="submit" className="primary-btn">Next <MdOutlineArrowRightAlt /></button>
                                        </div>
                                    </div>
                                </Form>
                            }
                            {activeStep == "third" &&
                                <Form action="" onSubmit={handleNextLawfirm2}>
                                    {passwordInput !== passwordInput1 ? <span style={{ fontSize: "11px" }}>Password does not match.</span> : passwordInput.length > 0 && passwordInput1.length > 0 && passwordInput === passwordInput1 ? <span className="text-success" style={{ fontSize: "11px" }}>Password match.</span> : <></>}
                                    <div className="row me-4">
                                        <div className="col-md-6">
                                            <Form.Control type={passwordType} required onChange={handlePasswordChange} value={passwordInput} className="bg-light px-3 py-2" placeholder="***************"></Form.Control><div style={{ marginTop: "-35px", marginRight: "20px", float: "right" }} >{passwordType === "password" ? <FaEyeSlash onClick={() => togglePassword()} /> : <FaEye onClick={() => togglePassword()} />}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type={passwordType1} required onChange={handlePasswordChange1} value={passwordInput1} className="bg-light px-3 py-2" placeholder="***************"></Form.Control><div style={{ marginTop: "-35px", marginRight: "20px", float: "right" }} >{passwordType1 === "password" ? <FaEyeSlash onClick={() => togglePassword1()} /> : <FaEye onClick={() => togglePassword1()} />}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-5 me-4">
                                        <div className="col-md-6">
                                            <button type="button" onClick={() => setActiveStep('second')} className="secondary-btn"> <CgArrowLongLeft /> Previous</button>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type="submit" disabled={passwordInput !== passwordInput1} className="primary-btn">Submit &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button>
                                        </div>
                                    </div>
                                </Form>
                            }
                        </>}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="md" centered show={signUpSuccess} onHide={() => setSignUpSuccess(false)}>
                <Modal.Body className="text-center bg-light loginBG px-4 py-5 rounded">
                    <div className="text-end text-white"><AiOutlineCloseCircle onClick={() => setShowSignup(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-80px -60px 0px 0px" }} /></div>
                    <div className="mt-5 mb-5">
                        <img src={SuccessIcon} alt="" />
                        <h4 className="text-primary py-5">Account Registration <br /><b>Successful!</b></h4>
                        <button onClick={() => { setSignUpSuccess(false); setShowLogin(false) }} className="primary-btn">Login &nbsp; &nbsp; &nbsp; <BsChevronRight /></button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Header;
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import client from "../../api/client";
import '../../styles/admin.css';
import logo from "../../images/legal-fuse.png";
import { Form, Modal, Navbar, NavDropdown, Spinner } from "react-bootstrap";
import { FaCheck, FaUserCircle } from "react-icons/fa";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { MdDelete } from "react-icons/md";
import Sidebar from "../../components/Layout";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import { BsArrowRight, BsPatchCheck } from "react-icons/bs";


const LawFirmsPage = () => {

    document.title = "Admin Dashboard - Legal Fuse";

    const location = useLocation();
    const navigate = useNavigate();

    const getUser = JSON.parse(localStorage.getItem("user"));

    const [lawFirms, setLawFirms] = useState([]);
    const [filteredLawFirms, setFilteredLawFirms] = useState([]);

    const [loading, setLoading] = useState(false);
    const [viewDetails, setViewDetails] = useState();
    const [show, setShow] = useState(false);

    const authenticate = () => {
        if (localStorage.getItem("user") === null || localStorage.getItem("user") === undefined) {
            navigate('/');
        }
    }

    useEffect(() => {
        authenticate();
    }, [getUser]);

    useEffect(() => {
        const getlawFirms = async () => {
            const res = await client.get(`/admin/authentication/getAllFirm`);
            if (res.data.statusCode === 200) {
                setLawFirms(res.data.data);
                setFilteredLawFirms(res.data.data);
            }
        }
        getlawFirms();
    }, [loading]);

    const activateLawFirm = async (id) => {
        setLoading(true);
        const status = {
            "verify": true
        };
        const res = await client.put(`/admin/authentication/updatefirm/${id}`, status);
        if (res.data.statusCode === 200) {
            setLoading(false);
        }
    };
    const deactivateLawFirm = async (id) => {
        setLoading(true);
        const status = {
            "verify": false
        };
        const res = await client.put(`/admin/authentication/updatefirm/${id}`, status);
        if (res.data.statusCode === 200) {
            setLoading(false);
        }
    }
    const deleteLawFirm = async (id) => {
        setLoading(true);
        const res = await client.delete(`/admin/authentication/deletefirm/${id}`);
        if (res.data.statusCode === 200) {
            setLoading(false);
        }
    }


    return (
        <Sidebar pageTitle={"Dashboard"}>
            <div className="py-4">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                        <Form.Select className="searchInput py-2" onChange={(e) => setFilteredLawFirms(lawFirms.filter(loc => loc.Location === e.target.value))}>
                            <option value="">Select Location</option>
                            <option value="Abia">Abia</option>
                            <option value="Adamawa">Adamawa</option>
                            <option value="Akwa Ibom">Akwa Ibom</option>
                            <option value="Anambra">Anambra</option>
                            <option value="Bauchi">Bauchi</option>
                            <option value="Bayelsa">Bayelsa</option>
                            <option value="Benue">Benue</option>
                            <option value="Borno">Borno</option>
                            <option value="Cross River">Cross River</option>
                            <option value="Delta">Delta</option>
                            <option value="Ebonyi">Ebonyi</option>
                            <option value="Edo">Edo</option>
                            <option value="Ekiti">Ekiti</option>
                            <option value="Enugu">Enugu</option>
                            <option value="FCT">Federal Capital Territory</option>
                            <option value="Gombe">Gombe</option>
                            <option value="Imo">Imo</option>
                            <option value="Jigawa">Jigawa</option>
                            <option value="Kaduna">Kaduna</option>
                            <option value="Kano">Kano</option>
                            <option value="Katsina">Katsina</option>
                            <option value="Kebbi">Kebbi</option>
                            <option value="Kogi">Kogi</option>
                            <option value="Kwara">Kwara</option>
                            <option value="Lagos">Lagos</option>
                            <option value="Nasarawa">Nasarawa</option>
                            <option value="Niger">Niger</option>
                            <option value="Ogun">Ogun</option>
                            <option value="Ondo">Ondo</option>
                            <option value="Osun">Osun</option>
                            <option value="Oyo">Oyo</option>
                            <option value="Plateau">Plateau</option>
                            <option value="Rivers">Rivers</option>
                            <option value="Sokoto">Sokoto</option>
                            <option value="Taraba">Taraba</option>
                            <option value="Yobe">Yobe</option>
                            <option value="Zamfara">Zamfara</option>
                        </Form.Select>
                    </div>
                    <div className="col-md-3">
                        <Form.Select className="searchInput py-2" onChange={(e) => setFilteredLawFirms(lawFirms.filter(loc => loc.areaOfPractice === e.target.value))}>
                            <option value="">Select Area of Practice</option>
                            <option value='Corporate Commercial'>Corporate Commercial</option>
                            <option value='Arbitration'>Arbitration</option>
                            <option value='Dispute'>Dispute</option>
                            <option value='Bankruptcy'>Bankruptcy</option>
                            <option value='Business Law'>Business Law</option>
                            <option value='Car Accident'>Car Accident</option>
                            <option value='Criminal Defense'>Criminal Defense</option>
                            <option value='Employment'>Employment</option>
                            <option value='Estate Planning'>Estate Planning</option>
                            <option value='Family Law'>Family Law</option>
                            <option value='Government'>Government</option>
                            <option value='Health Care & Social'>Health Care & Social</option>
                            <option value='Immigration'>Immigration</option>
                            <option value='Industry'>Industry</option>
                            <option value='Intellectual Property'>Intellectual Property</option>
                            <option value='International Law'>International Law</option>
                            <option value='Litigation'>Litigation</option>
                            <option value='Medical Malpractice'>Medical Malpractice</option>
                            <option value='Personal Injury'>Personal Injury</option>
                            <option value='Real Estate'>Real Estate</option>
                            <option value='Tax'>Tax</option>
                        </Form.Select>
                    </div>
                </div>
                <div style={{ height: "550px", overflow: "scroll" }}>
                    <table className="table table-responsive mt-4">
                        <thead className="text-secondary">
                            <tr>
                                <td>#</td><td>COMPANY NAME</td><td>EMAIL</td><td>LOCATION</td><td>STATUS</td><td>ACTION</td>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: "15px" }}>
                            {filteredLawFirms.map((list, key) => <tr key={key}><td style={{ padding: "18px 10px" }}>{key + 1}</td>
                                <td style={{ padding: "18px 10px" }}>{list.companyName}</td><td style={{ padding: "18px 0px" }}>{list.emailAddress}</td><td style={{ padding: "18px 10px" }}>{list.Location}</td><td style={{ padding: "18px 10px" }}>{list.isVerify === true ? "Verified" : "Not Verified"}</td><td style={{ padding: "18px 10px" }}><Link onClick={() => { setShow(true); setViewDetails(list) }} style={{ fontSize: "14px" }}>View</Link></td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal size="xl" className="detailsModal" centered show={show} onHide={() => setShow(false)}>
                <Modal.Body className="text-left p-5">
                    <div className="d-flex justify-content-between"><span>Law Firm</span><AiOutlineCloseCircle onClick={() => setShow(false)} style={{ cursor: "pointer", fontSize: "30px", fill: "#0e71f8" }} /></div>
                    <div className="card px-4 mt-3">
                        <div className="row mt-5 mb-5">
                            <div className="col-md-4">
                                <img src={`/legal-tech-uploads/${viewDetails?.ImgLocation}`} height="100%" width="100%" alt="" />
                                {viewDetails?.isVerify == true && <BsPatchCheck size={50} className="text-primary" style={{ float: "right", margin: "-80px 30px 0px 0px" }} />}
                            </div>
                            <div className="col-md-8 px-5">
                                <table width="100%">
                                    <tr>
                                        <td><span><b>{viewDetails?.companyName}</b></span> <br /><span> Dispute Law Firm</span></td>
                                        <td><span><b>Phone Number</b> </span> <br /><span> {viewDetails?.phoneNumber}</span></td>
                                        <td><span><b>Responsible Person</b> </span> <br /><span> {viewDetails?.responsiblePerson}</span></td>
                                    </tr>
                                    <tr><br /><br /></tr>
                                    <tr>
                                        <td><span><b>Email Address</b> </span> <br /><span>{viewDetails?.emailAddress}</span></td>
                                        <td><span><b>Location</b> </span> <br /><span> {viewDetails?.Location}</span></td>
                                        <td><span><b>Designation</b> </span> <br /><span> {viewDetails?.areaOfPractice}</span></td>
                                    </tr>
                                    <tr><br /><br /></tr>
                                    <tr>
                                        <td colSpan={2}><span><b>About {viewDetails?.firstName} {viewDetails?.lastName}</b> </span> <br /><span>{viewDetails?.BriefBio}</span></td>
                                    </tr>
                                    <tr><br /></tr>
                                    <tr><td colSpan={2}></td></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        {viewDetails?.isVerify == true ? <button type="button" onClick={() => deactivateLawFirm(viewDetails?.id)} className="secondary-btn px-5 mt-3"><AiOutlineClose /> Deactivate  {loading && <Spinner animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} />}</button> : <button type="button" onClick={() => activateLawFirm(viewDetails?.id)} className="primary-btn px-5 mt-3"> Verify &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {loading ? <Spinner animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} /> : <BsArrowRight />}</button>}
                        <button type="button" onClick={() => deleteLawFirm(viewDetails?.id)} className="danger-btn px-5 mt-3"><AiOutlineClose /> Delete {loading && <Spinner animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} />}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </Sidebar >
    )
}

export default LawFirmsPage;
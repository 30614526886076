import React, {useState} from "react";
import Header from "../components/Header";
import styles from '../styles/AboutPage.module.css';
import about from '../images/Rectangle 37.png';
import { Form } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import client from "../api/client";
import Spinner from 'react-bootstrap/Spinner';
import Footer from "../components/Footer";

const ResetPasswordPage = () => {

    document.title = "Password Reset - Legal Fuse";

    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const resetToken = searchParams.get("resetToken");
    const [type, setType] = useState();

    const [loading, setLoading] = useState(false);

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    };

    const handleChangePassword = async(e) => {
        e.preventDefault();
        setLoading(true);
        const input = {
            "type": type,
            "resetToken": resetToken,
            "password": passwordInput,
            "email": email
          }
        const res = await client.patch(`/firm/authentication/reset/${resetToken}/password`, input);
        if (res.data.statusCode === 200) {
            setLoading(false);
            alert("Password Changed Successfully");
        }
        else {
            setLoading(false);
            alert(res.data.message);
        }
    }

    return (
        <section>
            <Header/>
            <div className="container text-center mt-5 mb-5 pt-4 pb-4">
                <div className="card p-5 row col-md-6 m-auto">
                    <h3 className={styles.title}>Reset Password</h3>
                    <h5>Kindly fill in your new password to proceed.</h5>
                    <Form onSubmit={handleChangePassword} className="text-start mt-4">
                        <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Email</Form.Label>
                        <Form.Control type="email" value={email} className="form-control py-3 mb-4"/>
                        <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Reset Token</Form.Label>
                        <Form.Control type="text" value={resetToken} className="form-control py-3 mb-4"/>
                        <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Account Type (Lawyer OR Law Firm)</Form.Label>
                        <Form.Select required onChange={(e)=> setType(e.target.value)} className="form-control py-3 mb-4">
                            <option>Select your option</option>
                            <option value="Lawyer">Lawyer</option>
                            <option value="Law Firm">Law Firm</option>
                        </Form.Select>
                        <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Create New Password *</Form.Label>
                        <Form.Control type={passwordType} required onChange={handlePasswordChange} value={passwordInput} className="py-3" placeholder="***************"></Form.Control><div style={{float: "right", margin: "-45px 20px 0px 0px"}} >{passwordType === "password" ? <FaEyeSlash size={20} onClick={() => togglePassword()} /> : <FaEye size={20} onClick={() => togglePassword()} />}</div>
                        <button type="submit" className="primary-btn mt-4 px-5">Submit {loading === true && <Spinner variant="white" size="sm" />}</button>
                    </Form>
                </div>
            </div>
            <Footer/>
        </section>
    )
}

export default ResetPasswordPage;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/SignupLawyer.module.css";
import client from "../api/client";
import Select from "react-select";
import { Form, Modal } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import FindLawyer from '../images/legal-fuse.png';
import Group8 from '../images/Group8.png';
import law from '../images/law.png';
import successIcon from '../images/successIcon.svg';
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {CgArrowLongLeft} from "react-icons/cg";
import { MdClose, MdOutlineArrowRightAlt, MdOutlineArrowLeft } from "react-icons/md";
import Spinner from 'react-bootstrap/Spinner';
import Alert from "react-bootstrap/Alert";

const SignupLawFirm = () => {

    document.title = "Register as a Law Firm - Legal Fuse";

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('first');

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [loading, setLoading] = useState(true);

    const [lawFirm, setLawFirm] = useState();

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordInput1, setPasswordInput1] = useState("");
    const handlePasswordChange1 = (evnt) => {
        setPasswordInput1(evnt.target.value);
    }
    const togglePassword1 = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }

    const [inputList, setInputList] = useState([{ "name": "", "designation": "" }]);
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
      };
    
      // handle click event of the Remove button
      const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
      };
    
      // handle click event of the Add button
      const handleAddClick = () => {
        setInputList([...inputList, { "name": "", "designation": "" }]);
      };

    const handleNext = async(e) => {
        e.preventDefault();
        setActiveTab("second");
    }
    const handleNext1 = async(e) => {
        e.preventDefault();
        const AreaOfPractice = skillOptions.map(list => (list.value));
        setLawFirm({...lawFirm, areaOfPractice: AreaOfPractice, responsiblePerson: JSON.stringify(inputList)});
        setActiveTab("third");
    }
    const handleNext2 = async(e) => {
        e.preventDefault();
        setLoading(false);
        const input = ({...lawFirm, password: passwordInput});
        const formData = new FormData();
        formData.append("emailAddress", lawFirm.emailAddress);
        formData.append("password", passwordInput);
        formData.append("companyName", lawFirm.companyName);
        formData.append("website", lawFirm.website);
        formData.append("address", lawFirm.address);
        formData.append("phoneNumber", lawFirm.phoneNumber);
        formData.append("responsiblePerson", lawFirm.responsiblePerson);
        formData.append("areaOfPractice", lawFirm.areaOfPractice);
        formData.append("BriefBio", lawFirm.BriefBio);
        formData.append("Location", lawFirm.Location);
        formData.append("file", lawFirm.file);
        
        const res = await client.post('/firm/authentication/registerFirm', formData);
        if (res.data.statusCode === 200  ) {
            setLoading(true);
            setShow(true);
        }
        else {
            setLoading(true);
            alert(res.data.message);
        }
    }

    const [skillOptions, setSkillOptions] = useState(null);

    const options = [
        { value: 'Corporate Commercial', label: 'Corporate Commercial' },
        { value: 'Arbitration', label: 'Arbitration' },
        { value: 'Dispute', label: 'Dispute' },
        { value: 'Bankruptcy', label: 'Bankruptcy' },
        { value: 'Business Law', label: 'Business Law'},
        { value: 'Car Accident', label: 'Car Accident'},
        { value: 'Criminal Defense', label: 'Criminal Defense'},
        { value: 'Employment', label: 'Employment'},
        { value: 'Estate Planning', label: 'Estate Planning'},
        { value: 'Family Law', label: 'Family Law'},
        { value: 'Government', label: 'Government'},
        { value: 'Health Care & Social', label: 'Health Care & Social'},
        { value: 'Immigration', label: 'Immigration'},
        { value: 'Industry', label: 'Industry'},
        { value: 'Intellectual Property', label: 'Intellectual Property'},
        { value: 'International Law', label: 'International Law'},
        { value: 'Litigation', label: 'Litigation'},
        { value: 'Medical Malpractice', label: 'Medical Malpractice'},
        { value: 'Personal Injury', label: 'Personal Injury'},
        { value: 'Real Estate', label: 'Real Estate'},
        { value: 'Tax', label: 'Tax'}
      ];


    //////////////////////////////////////////////////////////////////////////

    const [signIn, setSignIn] = useState();
    const [errorLogin, setErrorLogin] = useState();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(false);
        const res = await client.post('/firm/authentication/login', signIn);
        if (res.data.statusCode === 200) {
            setLoading(true);
            navigate('/dashboard');
        }
        else {
            setErrorLogin(
                <Alert variant="danger">
                    {res.message}
                </Alert>
            );
        }
    };

    

    return (
        <div className="container">
            <Navbar collapseOnSelect expand="lg" variant="light" className="mt-3 mb-2">
                <Container>
                    <Navbar.Brand href="/"><img src={FindLawyer} width="50px" alt="" /> &nbsp;&nbsp;<span className={styles.brandName}>LEGAL </span> <span className={styles.brandName1}>FUSE</span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav>
                            <Nav.Link href="/" className={styles.backButton}>Back to Website <MdOutlineArrowRightAlt /></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="row mt-4">
                <div className="col-md-7">
                    <h2 className={styles.title}>Register a new account</h2>
                    <button onClick={() => navigate('/signup-lawyer')} className={styles.switchButttonInactive}><img src={law} alt="" /><span className={styles.blockText}><label>Register as a </label><br/>Lawyer</span></button>
                    <button onClick={() => navigate('/signup-law-firm')} className={styles.switchButtton1}><img src={law} alt="" /><span className={styles.blockText}><label>Register as a </label><br/>Law Firm</span></button>

                    <Form action="" onSubmit={handleNext}>{activeTab === 'first' && <div>
                        <div className="row mt-5">
                            <div className="col-md-1"><span className={styles.tabCount}>1<label>/3</label></span> </div>
                            <div className="col-md-6"><span className={styles.formLabel}>Company Details <label>Provide your company details in the fields below</label></span></div>
                        </div>
                        <div className={styles.onboardingForm}>
                            <hr/>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Company Name *</Form.Label>
                                    <Form.Control type="text" required placeholder="The Horizon Law Firm" onChange={(e) => setLawFirm({...lawFirm, companyName: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Website *</Form.Label>
                                    <Form.Control type="text" required placeholder="https://www.companyname.com" onChange={(e) => setLawFirm({...lawFirm, website: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Email Address *</Form.Label>
                                    <Form.Control type="email" required placeholder="info@companyname.com" onChange={(e) => setLawFirm({...lawFirm, emailAddress: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Phone Number *</Form.Label>
                                    <Form.Control type="text" required placeholder="0987654321" onChange={(e) => setLawFirm({...lawFirm, phoneNumber: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Upload Company Logo *</Form.Label>
                                    <Form.Control type="file" required accept="image/x-png,image/jpg,image/jpeg" onChange={(e) => setLawFirm({...lawFirm, file: e.target.files[0]})} className={styles.inputField}></Form.Control><label className={styles.formTag}>Maximum file size is 10mb</label>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Company Address *</Form.Label>
                                    <Form.Control type="text" required placeholder="Street Name, City, State" onChange={(e) => setLawFirm({...lawFirm, address: e.target.value})} className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row col-md-4 m-auto mt-5 mb-5"><button type="submit" className={styles.backButton}>Next <MdOutlineArrowRightAlt /></button></div>
                        </div>
                    </div>}
                    </Form>

                    <Form action="" onSubmit={handleNext1}>{activeTab === 'second' && <div>
                        <div className="row mt-5">
                            <div className="col-md-1 p-0 m-0"><span className={styles.tabCount}>2<label>/3</label></span> </div>
                            <div className="col-md-6"><span className={styles.formLabel}>Company Details <label>Provide your company details in the fields below</label></span></div>
                        </div>
                        <div className={styles.onboardingForm}>
                            <hr/>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Area of Practice *</Form.Label>
                                    {/* <Form.Select className={styles.inputField}>
                                        <option>Select an option</option>
                                    </Form.Select> */}
                                    <Select
                                        isMulti
                                        name="skills"
                                        options={options}
                                        onChange={setSkillOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Label for="locations" className={styles.formLabel}>&nbsp;&nbsp;Location *</Form.Label>
                                    <Form.Select name="locations" id="locations" required className={styles.inputField} onChange={(e)=> setLawFirm({...lawFirm, Location: e.target.value})}>
                                        <option value="">Select location</option>
                                        <option value="Abia">Abia</option>
                                        <option value="Adamawa">Adamawa</option>
                                        <option value="Akwa Ibom">Akwa Ibom</option>
                                        <option value="Anambra">Anambra</option>
                                        <option value="Bauchi">Bauchi</option>
                                        <option value="Bayelsa">Bayelsa</option>
                                        <option value="Benue">Benue</option>
                                        <option value="Borno">Borno</option>
                                        <option value="Cross River">Cross River</option>
                                        <option value="Delta">Delta</option>
                                        <option value="Ebonyi">Ebonyi</option>
                                        <option value="Edo">Edo</option>
                                        <option value="Ekiti">Ekiti</option>
                                        <option value="Enugu">Enugu</option>
                                        <option value="FCT">Federal Capital Territory</option>
                                        <option value="Gombe">Gombe</option>
                                        <option value="Imo">Imo</option>
                                        <option value="Jigawa">Jigawa</option>
                                        <option value="Kaduna">Kaduna</option>
                                        <option value="Kano">Kano</option>
                                        <option value="Katsina">Katsina</option>
                                        <option value="Kebbi">Kebbi</option>
                                        <option value="Kogi">Kogi</option>
                                        <option value="Kwara">Kwara</option>
                                        <option value="Lagos">Lagos</option>
                                        <option value="Nasarawa">Nasarawa</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Ogun">Ogun</option>
                                        <option value="Ondo">Ondo</option>
                                        <option value="Osun">Osun</option>
                                        <option value="Oyo">Oyo</option>
                                        <option value="Plateau">Plateau</option>
                                        <option value="Rivers">Rivers</option>
                                        <option value="Sokoto">Sokoto</option>
                                        <option value="Taraba">Taraba</option>
                                        <option value="Yobe">Yobe</option>
                                        <option value="Zamfara">Zamfara</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12 row m-0 p-0">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Responsible Person. *     <span>(Maximum of 3)</span></Form.Label>

                                    {inputList.map((x, i) => {
                                        return ( <div className="row">
                                            <div className="col-md-6">
                                                <Form.Control type="text" required name="name" placeholder="Principal person's name" value={x.name}
                                                onChange={e => handleInputChange(e, i)} className={styles.inputField}></Form.Control>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Control type="text" required name="designation" placeholder="Designation" value={x.designation}
                                                onChange={e => handleInputChange(e, i)} className={styles.inputField}></Form.Control>
                                            </div>
                                            <div className="btn-box">
                                                    {inputList.length !== 1 && <button
                                                        className="mr10 bg-danger text-light"
                                                        onClick={() => handleRemoveClick(i)} style={{border: "none", borderRadius: "5px", padding: "5px 10px", marginRight: "10px", marginBottom: "10px"}}>Remove</button>}
                                                    {inputList.length < 3 && inputList.length - 1 === i && <button className="bg-success text-light" onClick={handleAddClick}  style={{border: "none", borderRadius: "5px", padding: "5px 10px"}}>Add</button>}
                                                </div>
                                        </div>
                                        );
                                    })}

                                    {/* {inputList.map((x, i) => {
                                    return (
                                        <div>
                                            <div
                                                className='row'
                                                style={{ marginBottom: '-50px' }}
                                            >
                                                <div className='col-md-5'>
                                                    <Form.Label className={styles.label}>
                                                        {' '}
                                                        Email<span>*</span>{' '}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='email'
                                                        required
                                                        name='email'
                                                        value={x.email}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        placeholder='samseyi@gmail.com'
                                                        className={styles.input}
                                                    ></Form.Control>
                                                </div>
                                            <div className='col-md-3'>
                                                <Form.Label className={styles.label}> {' '} Assign role<span>*</span>{' '}  </Form.Label>
                                                <Form.Select
                                                    required
                                                    name='assignRole'
                                                    value={x.assignRole}
                                                    onChange={(e) => handleInputChange(e, i)}
                                                    className={styles.input}
                                                >
                                                    <option value=''>Select an option</option>
                                                    <option value='Contributor'>
                                                    Contributor
                                                    </option>
                                                    <option value='Co Author'>Co Author</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-1 mt-4 pt-2'>
                                                {inputList.length !== 1 && (
                                                    <button
                                                    className={styles.removeInput}
                                                    onClick={() => handleRemoveClick(i)}
                                                    >
                                                    {' '}
                                                    -{' '}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className='mb-5 mt-5'>
                                            {inputList.length - 1 === i && (
                                            <button
                                                onClick={handleAddClick}
                                                className={styles.addInput}
                                            >
                                                {' '}
                                                + Add
                                            </button>
                                            )}
                                        </div>
                                        </div>
                                    );
                                    })} */}
                                    

                                    {/* <div className="col-md-6">
                                        <Form.Control type="text" required placeholder="Principal 1 person's name" onChange={(e) => setLawFirm({...lawFirm, principalPerson1: e.target.value})} className={styles.inputField}></Form.Control>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Control type="text" placeholder="Principal 2 person's name" onChange={(e) => setLawFirm({...lawFirm, principalPerson2: e.target.value})} className={styles.inputField}></Form.Control>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <Form.Control type="text" placeholder="Principal 3 person's name" onChange={(e) => setLawFirm({...lawFirm, principalPerson3: e.target.value})} className={styles.inputField}></Form.Control>
                                    </div> */}
                                </div>
                            </div>
                            <div className="row mt-4 p-0">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;About the Law Firm *</Form.Label>
                                    <Form.Text as="textarea" required maxlength="500" placeholder="Tell us about yourself" rows={3} onChange={(e)=> setLawFirm({...lawFirm, BriefBio: e.target.value})} className={styles.textareaField}></Form.Text>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5">
                                <div className="col-md-6">
                                    <button type="button" onClick={() => setActiveTab('first')} className={styles.backButton}> <CgArrowLongLeft /> Previous</button>
                                </div>
                                <div className="col-md-6 text-end">
                                    <button type="submit" className={styles.backButton}>Next <MdOutlineArrowRightAlt /></button>
                                </div>
                            </div>
                        </div>
                    </div>}
                    </Form>

                    <Form action="" onSubmit={handleNext2}>{activeTab === 'third' && <div>
                        <div className="row mt-5">
                            <div className="col-md-1 p-0 m-0"><span className={styles.tabCount}>3<label>/3</label></span> </div>
                            <div className="col-md-6"><span className={styles.formLabel}>Create Password <label>Last step is to create a password for your account</label></span></div>
                        </div>
                        <div className={styles.onboardingForm}>
                            <hr/>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Create Password *</Form.Label>
                                    <Form.Control type={passwordType} required onChange={handlePasswordChange} value={passwordInput} className={styles.inputField} placeholder="***************"></Form.Control><div className={styles.pass} >{passwordType === "password" ? <FaEyeSlash onClick={() => togglePassword()} /> : <FaEye onClick={() => togglePassword()} />}</div>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Conf<b>i</b>rm Password *  &nbsp; {passwordInput !== passwordInput1 ? <span className={styles.passValid}>Password does not match.</span> : passwordInput.length > 0 && passwordInput1.length > 0 && passwordInput === passwordInput1 ? <span className="text-success" style={{fontSize: "14px"}}>Password match.</span> : <></> }</Form.Label>
                                    <Form.Control type={passwordType1} required onChange={handlePasswordChange1} value={passwordInput1} className={styles.inputField} placeholder="***************"></Form.Control><div className={styles.pass} >{passwordType1 === "password" ? <FaEyeSlash onClick={() => togglePassword1()} /> : <FaEye onClick={() => togglePassword1()} />}</div>
                                </div>
                            </div>
                            {/* <div className="row mt-5">
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Create Password</Form.Label>
                                    <Form.Control type="password" required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters" placeholder="************" className={styles.inputField}></Form.Control>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Conf<b>i</b>rm Password</Form.Label>
                                    <Form.Control type="password" placeholder="************" className={styles.inputField}></Form.Control>
                                </div>
                            </div> */}
                            <div className="row mt-5 mb-5">
                                <div className="col-md-6">
                                    <button type="button" onClick={() => setActiveTab('second')} className={styles.backButton}> <CgArrowLongLeft /> Previous</button>
                                </div>
                                <div className="col-md-6 text-end">
                                    <button type="submit" className={styles.backButton}>Submit &nbsp; <Spinner hidden={loading} animation="border" variant="light" style={{fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle"}} /></button>
                                </div>
                            </div>
                        </div>
                    </div>}
                    </Form>
                </div>
                <div className="col-md-5"><img src={Group8} className={styles.onboardingBg} alt="" /></div>
            </div>
            <Modal size="md" centered show={show} onHide={()=> setShow(false)}>
                <Modal.Body className="text-center">
                    <div style={{display: 'block', textAlign: 'right', marginRight: '10px'}}><MdClose onClick={()=> setShow(false)} /></div>
                    <div className="px-5 pt-3 pb-5">
                        <img src={successIcon} alt="" />
                        <h4 className={styles.successBig}>Registration Completed</h4>
                        <p className={styles.successSmall}>You have successfully registered on FindLawyers. </p>
                        <button onClick={()=> {setShow(false); setShow1(true)}} className={styles.backButton}>Login Here</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="md" centered show={show1} onHide={()=> setShow1(false)}>
                <Modal.Body>
                    <div style={{display: 'block', textAlign: 'right', marginRight: '10px'}}><MdClose onClick={()=> setShow(false)} /></div>
                    <div className="pb-2">
                        <Form action="" onSubmit={handleLogin} className={styles.onboardingForm}>
                        <h3 align="center">Welcome back</h3>
                        <h5 align="center">Login here</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Email Address:</Form.Label>
                                    <Form.Control type="email" required onChange={(e)=> setSignIn({...signIn, email: e.target.value})} placeholder="Mark" className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Password</Form.Label>
                                    <Form.Control type="password" required onChange={(e)=> setSignIn({...signIn, password: e.target.value})} placeholder="John" className={styles.inputField}></Form.Control>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <Form.Label className={styles.formLabel}>&nbsp;&nbsp;Account Type (Lawyer OR Law Firm)</Form.Label>
                                    <Form.Select required onChange={(e)=> setSignIn({...signIn, accountType: e.target.value})} className={styles.inputField}>
                                        <option>Select your option</option>
                                        <option value="Lawyer">Lawyer</option>
                                        <option value="Law Firm">Law Firm</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="text-center"><button type="submit"  className={styles.backButton}>Login <Spinner hidden={true} animation="border" variant="light" /></button></div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SignupLawFirm;
import React, { useEffect, useState } from "react";
import client from "../api/client";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { Form, Modal, Spinner } from "react-bootstrap";
import Header from "../components/Header";
import { FaArrowRight, FaRegEnvelope } from "react-icons/fa";
import Footer from "../components/Footer";
import '../styles/LandingPage.css';
import OutlineLogo from '../assets/logo-outline.svg';
import Img1 from '../assets/judge-s-gavel-sits-desk-court-room.png';
import Img2 from '../assets/business-lawyers.png';
import LadyLawyer from '../assets/lady-lawyer.png';
import SearchIcon from '../assets/Search.svg';
import MessageIcon from '../assets/Message.svg';
import PenIcon from '../assets/Pen.svg';
import ShieldIcon from '../assets/vector87.png';
import Lawfirm1 from '../assets/lawfirm1.png';
import Lawfirm2 from '../assets/lawfirm2.png';
import Lawfirm3 from '../assets/lawfirm3.png';
import Partern1 from '../assets/partern.svg';
import Lawyer1 from '../assets/vector-lawyer.png';
import Lawyer2 from '../assets/vector-layer01.png';
import Lawyer3 from '../assets/vectorlaw09.png';
import Lawyer4 from '../assets/vector-law123.png';
import Lawfirm from '../assets/vector-logo.svg';
import Testimonial from '../assets/vector-user.png';
import { BsArrowRight, BsPatchCheck } from 'react-icons/bs';





const LandingPage = () => {

    document.title = "Welcome Home - Legal Fuse";

    const navigate = useNavigate();

    const [signIn, setSignIn] = useState();
    const [searchData, setSearchData] = useState();
    const [searchResultData, setSearchResultData] = useState();

    const [lawyers, setLawyers] = useState();
    const [lawFirms, setLawFirms] = useState();
    const [viewMore, setViewMore] = useState("null");
    const [viewMore1, setViewMore1] = useState("null");

    const [show, setShow] = useState(false);
    const [showSendMessage, setShowSendMessage] = useState(false);
    const [showSendMessage1, setShowSendMessage1] = useState(false);
    const [show1, setShow1] = useState(false);
    const [showTextarea, setShowTextarea] = useState(true);
    const [sendMessageLawyer, setSendMessageLawyer] = useState();
    const [sendMessageLawFirm, setSendMessageLawFirm] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const getLawyers = async () => {
            const res = await client.get('/general/getAllLawyer');
            if (res.status === 200) {
                setLawyers(res?.data?.data);
            }
        };
        const getLawFirms = async () => {
            const res = await client.get('/general/getAllFirm');
            if (res.status === 200) {
                setLawFirms(res?.data?.data);
            }
        };
        getLawyers();
        getLawFirms();
    }, []);

    const handleClickScroll = () => {
        const element = document.getElementById('search');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const handleSearch = async (e) => {
        e.preventDefault();
        navigate(`search-page/?type=${searchData.type === undefined ? "" : searchData.type}&areaOfPractice=${searchData.AreaOfPractice === undefined ? "" : searchData.AreaOfPractice}&location=${searchData.location === undefined ? "" : searchData.location}`);
        // const res = await client.post('/general/filter', searchData);
        // if (res.data.statusCode === 200) {
        //     setSearchResultData(res.data);  
        // };
    };

    const handleMessageLawFirm = async (e) => {
        e.preventDefault();
        setLoading(true);
        const input = {
            "type": "Law Firm",
            "email": viewMore1.emailAddress,
            "message": sendMessageLawFirm.message,
            "senderName": sendMessageLawFirm.senderName,
            "senderEmail": sendMessageLawFirm.senderEmail,
            "senderPhoneNumber": sendMessageLawFirm.senderPhoneNumber
        };
        const res = await client.post('/general/sendmessage', input);
        if (res.data.statusCode === 200) {
            setLoading(false);
            alert(<span>Your message has been sent.</span>);
        }
        else {
            setLoading(false);
            alert(<span>{res.data.message}</span>)
        }
    }

    const handleMessageLawyer = async (e) => {
        e.preventDefault();
        setLoading(false);
        const input = {
            "type": "Lawyer",
            "email": viewMore.email,
            "message": sendMessageLawyer.message,
            "senderName": sendMessageLawyer.senderName,
            "senderEmail": sendMessageLawyer.senderEmail,
            "senderPhoneNumber": sendMessageLawyer.senderPhoneNumber
        };
        const res = await client.post('/general/sendmessage', input);
        if (res.data.statusCode === 200) {
            setLoading(true);
            alert(<span>Your message has been sent.</span>);
        }
        else {
            setLoading(true);
            alert(<span>{res.data.message}</span>)
        }
    }


    return (
        <main>
            <Header />
            <section className="section1">
                <div className="container">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-4 m-auto">
                            <img src={OutlineLogo} />
                        </div>
                        <div className="col-md-8 m-auto">
                            <h2 className="mb-5"><b>Need a Lawyer?</b> <br /> You have come to the right place!</h2>
                            <a href="#search"><button className="primary-btn py-3">Let's get you one</button></a>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container section2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-8 mb-2">
                            <img src={Img1} height="100%" width="100%" />
                        </div>
                        <div className="col-md-4 mb-2">
                            <img src={Img2} height="100%" width="100%" />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-8 m-auto mb-3">
                            <p><b className="text-primary">Legal Fuse</b> is here to help you connect with experienced and qualified lawyers and law firms from different area of practice in different locations to provide any kind of assistance in any legal issues.</p>
                            <div className="mt-5 d-flex justify-content-between gap-4">
                                <div className="w-100"><button className="search-icon-button"><img src={SearchIcon} /><br />Search for a Lawyer</button></div>
                                <div className="w-100"><button className="search-icon-button1"><img src={MessageIcon} /><br />Search for a Lawyer</button></div>
                                <div className="w-100"><button className="search-icon-button2"><img src={PenIcon} className="mb-3" height="50px" /><br />Search for a Lawyer</button></div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src={LadyLawyer} height="100%" width="100%" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section3">
                <div className="container pt-5">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-4 m-auto">
                            <img src={ShieldIcon} width="100%" />
                        </div>
                        <div className="col-md-7 m-auto">
                            <div className="textInside">
                                <h2 className="pb-3">Hire from our wide range of Verified legal practitioners</h2>
                                <p className="pt-3 mb-4 me-5">We have a large pool of qualified, and professional lawyers and law firms who are readily available to provide assistance on any kind of legal issues.</p>
                                <a href="#search"><button className="primary-btn py-3 mt-3">Let's get you one</button></a>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </section>

            <section className="hire-section">
                <div className="container pb-5">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-4 text-end m-auto">
                            <img src={Lawfirm1} width="100%"/>
                            <button className="secondary-btn py-4 px-5 me-4">Lawyers</button>
                        </div>
                        <div className="col-md-4 text-end m-auto">
                            <img src={Lawfirm2} width="100%"/>
                            <button className="secondary-btn py-4 px-5 me-4">Law Firms</button>
                        </div>
                        <div className="col-md-4 text-end m-auto">
                            <img src={Lawfirm3} width="100%"/>
                            <button className="secondary-btn py-4 px-5 me-4">Legal Consultants</button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="search" className="search-bg">
                <div className="row">
                    <div className="col-md-5 m-auto text-center px-4">
                        <div className="searchBox text-white">
                            <h4>Find a <b>Lawyer</b></h4>
                            <p>Let's get you the best lawyer <br /> for your legal needs</p>
                            <Form onSubmit={handleSearch} className="mt-5">
                                <Form.Select required onChange={(e) => setSearchData({ ...searchData, type: e.target.value })} className="searchInput">
                                    <option>Select type</option>
                                    <option value="Lawyer">Lawyer</option>
                                    <option value="Law Firm">Law Firm</option>
                                </Form.Select>
                                <Form.Select required onChange={(e) => setSearchData({ ...searchData, AreaOfPractice: e.target.value })} className="searchInput">
                                    <option>Select area of practice</option>
                                    <option value="Corporate Commercial">Corporate Commercial</option>
                                    <option value="Arbitration">Arbitration</option>
                                    <option value="Dispute">Dispute</option>
                                    <option value="Bankruptcy">Bankruptcy</option>
                                    <option value="Business Law">Business Law</option>
                                    <option value="Car Accident">Car Accident</option>
                                    <option value="Criminal Defense">Criminal Defense</option>
                                    <option value="Employment">Employment</option>
                                    <option value="Estate Planning">Estate Planning</option>
                                    <option value="Family Law">Family Law</option>
                                    <option value="Government">Government</option>
                                    <option value="Health Care & Social">Health Care & Social</option>
                                    <option value="Immigration">Immigration</option>
                                    <option value="Industry">Industry</option>
                                    <option value="Intellectual Property">Intellectual Property</option>
                                    <option value="International Law">International Law</option>
                                    <option value="Litigation">Litigation</option>
                                    <option value="Medical Malpractice">Medical Malpractice</option>
                                    <option value="Personal Injury">Personal Injury</option>
                                    <option value="Real Estate">Real Estate</option>
                                    <option value="Tax">Tax</option>
                                </Form.Select>
                                <Form.Select required onChange={(e) => setSearchData({ ...searchData, location: e.target.value })} className="searchInput">
                                    <option>Select location</option>
                                    <option value="Abia">Abia</option>
                                    <option value="Adamawa">Adamawa</option>
                                    <option value="Akwa Ibom">Akwa Ibom</option>
                                    <option value="Anambra">Anambra</option>
                                    <option value="Bauchi">Bauchi</option>
                                    <option value="Bayelsa">Bayelsa</option>
                                    <option value="Benue">Benue</option>
                                    <option value="Borno">Borno</option>
                                    <option value="Cross River">Cross River</option>
                                    <option value="Delta">Delta</option>
                                    <option value="Ebonyi">Ebonyi</option>
                                    <option value="Edo">Edo</option>
                                    <option value="Ekiti">Ekiti</option>
                                    <option value="Enugu">Enugu</option>
                                    <option value="FCT">Federal Capital Territory</option>
                                    <option value="Gombe">Gombe</option>
                                    <option value="Imo">Imo</option>
                                    <option value="Jigawa">Jigawa</option>
                                    <option value="Kaduna">Kaduna</option>
                                    <option value="Kano">Kano</option>
                                    <option value="Katsina">Katsina</option>
                                    <option value="Kebbi">Kebbi</option>
                                    <option value="Kogi">Kogi</option>
                                    <option value="Kwara">Kwara</option>
                                    <option value="Lagos">Lagos</option>
                                    <option value="Nasarawa">Nasarawa</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Ogun">Ogun</option>
                                    <option value="Ondo">Ondo</option>
                                    <option value="Osun">Osun</option>
                                    <option value="Oyo">Oyo</option>
                                    <option value="Plateau">Plateau</option>
                                    <option value="Rivers">Rivers</option>
                                    <option value="Sokoto">Sokoto</option>
                                    <option value="Taraba">Taraba</option>
                                    <option value="Yobe">Yobe</option>
                                    <option value="Zamfara">Zamfara</option>
                                </Form.Select>
                                <button type="submit" className="primary-btn-1 my-3 py-3 px-3 w-100"> Search</button>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-4 ps-5 m-auto">
                            <img src={Partern1} width="100%" />
                        </div>
                        <div className="col-md-5 pt-5 mt-5">
                            <div className="textInside">
                                <h2>Featured</h2>
                                <h4>Lawyers and Law Firms</h4>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </section>

            <section className="section4">
                <div className="container pb-5">
                    <div className="row mt-5 align-item-start">
                        {lawyers?.length === 0 ? "No lawyer available" : lawyers?.slice(0, 4).filter(lawyers => lawyers.isVerify === true).map((list, key) => <div key={key} className="col-md-3 mb-5 text-center boxCard ml-auto">
                            <img src={list.ImgLocation ? `/legal-tech-uploads/${list.ImgLocation}` : Lawyer1} width="100%" />
                            <div className="overlay-image"></div>
                            <div className="inside-text-button ps-4 text-start text-white">
                                <span className="text-wrap"><b>{list?.firstName} {list?.lastName}</b></span><br /><span style={{ fontSize: "14px" }}>{list?.AreaOfPractice}</span>
                            </div>
                            <button onClick={() => { setShow(true); setViewMore(list) }} className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                        </div>)}
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row text-start col-md-4 ps-5 ms-auto">
                        <img src={Partern1} width="100%" style={{ marginBottom: "-300px" }} />
                    </div>
                    <div className="row mb-5">
                        {lawFirms?.length === 0 ? "No lawyer available" : lawFirms?.slice(0, 4).filter(lawFirms => lawFirms.isVerify === true).map((list, key) =>
                            <div key={key} className="col-md-3 text-center boxCard ml-auto">
                                <div className="bg-light faded-overlay rounded p-5">
                                    <img src={list.ImgLocation ? `/legal-tech-uploads/${list.ImgLocation}` : Lawfirm} width="50%" className="mb-5" />
                                </div>
                                <div className="inside-text-button text-white ps-4 text-start">
                                    <span>{list?.companyName}</span><br /><span style={{ fontSize: "14px" }}>{list?.areaOfPractice}</span>
                                </div>
                                <button onClick={() => { setShow1(true); setViewMore1(list) }} className="primary-btn mt-4 py-3 px-5">Reach Out</button>
                            </div>)}
                    </div>
                </div>
            </section>

            <section className="pb-5">
                <div className="row mb-5 pb-5">
                    <div className="col-md-4 ps-5 m-auto">
                    </div>
                    <div className="col-md-5 pt-5 mt-5">
                        <div className="textInside">
                            <h2>Testimonials</h2>
                            <h4>What our clients say about us</h4>
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                </div>
                <div className="row testimonial-bg mx-0 mt-5 mb-5 pb-4">
                    <div className="col-md-4 p-0">
                        <img src={Testimonial} width="100%" style={{ marginTop: "-250px" }} />
                    </div>
                    <div className="col-md-6 text-white m-auto">
                        <p>“Legal Fuse still remain the best plug that connect lawyers and law firms with individuals for personal and business needs. I've been engaged with more legal service ever since I've enlisted myself on Legal Fuse. I will recommend Legal Fuse to other lwayers and law firms, looking for online engagement. As well as individual looking for lawyers or law firms to handle their cases.”</p>
                        <h6 className="fs-5 pt-4"><b>Akinlade Stephen Esq.</b></h6>
                        <span className="fw-100">Corporate Lawyer</span>
                    </div>
                    <div className="col-md-2 m-auto"></div>
                </div>
            </section>

            {/* <div className="row mt-3 mb-5" style={{ display: "flex", flexWrap: "wrap" }}>
                {lawyers?.data?.length === 0 ? "No lawyer available" : lawyers?.data?.slice(0, 4).filter(lawyers => lawyers.isVerify === true).map((list, key) =>
                    <div key={key} className="col-md-3 col-sm-6 col-xs-12 p-3">
                        <div className="card p-1" style={{ height: "300px" }}>
                            <img src={`/legal-tech-uploads/${list.ImgLocation}`} onClick={() => { setViewMore(list); setShow(true) }} height="100%" style={{ cursor: "pointer" }} className="lawyerList" alt="" />
                            <div onClick={() => { setViewMore(list); setShow(true) }} style={{ cursor: "pointer" }} className="lawyerName">{list.firstName} {list.lastName}</div>
                        </div>
                    </div>)}
            </div> */}


            <Footer />

            <Modal size="xl" centered show={show} onHide={() => setShow(false)}>
                <Modal.Body className="text-left text-white" style={{ backgroundColor: "#021228", padding: "120px" }}>
                    <div className="text-end"><AiOutlineCloseCircle onClick={() => setShow(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    <div className="row mt-0 mb-3">
                        <div className="col-md-4">
                            <img src={`/legal-tech-uploads/${viewMore?.ImgLocation}`} height="100%" width="100%" alt="" />
                            {viewMore?.isVerify && <BsPatchCheck size={50} className="text-primary" style={{ position: "absolute", float: "right", margin: "250px 0px 0px -80px" }} />}
                        </div>
                        <div className="col-md-8 px-5">
                            <table width="100%">
                                <tr>
                                    <td><span><b>{viewMore?.firstName} {viewMore?.lastName}</b></span> <br /><span> Dispute Lawyer</span></td>
                                    <td><span><b>Phone Number</b> </span> <br /><span> {viewMore.phoneNumber}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td><span><b>Email Address</b> </span> <br /><span>{viewMore?.email}</span></td>
                                    <td><span><b>Location</b> </span> <br /><span> {viewMore?.Location}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td colSpan={2}><span><b>About {viewMore?.firstName} {viewMore?.lastName}</b> </span> <br /><span>{viewMore.BriefBio}</span></td>
                                </tr>
                                <tr><br /></tr>
                                <tr><td colSpan={2}><button onClick={() => setShowSendMessage(!showSendMessage)} className="btn text-white"> <FaRegEnvelope /> &nbsp; Send a message</button></td></tr>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="md" centered show={showSendMessage} onHide={() => setShowSendMessage(false)} style={{ backgroundColor: "#0A3B81F7" }}>
                <Modal.Body style={{ backgroundColor: "#0A3B81F7", border: "#FFFFFF solid 1px", borderRadius: "20px" }}>
                    <div className="text-end text-white"><AiOutlineCloseCircle onClick={() => setShowSendMessage(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    <Form action="" onSubmit={handleMessageLawyer} className="px-4 py-5 text-center text-white">
                        <h4>Send a <b className="fw-bold">message</b></h4>
                        <Form.Control type="text" className="mt-5 px-3 py-3" placeholder="Name" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, senderName: e.target.value })}></Form.Control>
                        <Form.Control type="text" className="mt-4 px-3 py-3" placeholder="Email" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, senderEmail: e.target.value })}></Form.Control>
                        <Form.Control as="textarea" className="mt-4 px-3 py-3" onChange={(e) => setSendMessageLawyer({ ...sendMessageLawyer, message: e.target.value })} placeholder="Your Message" rows={5} />
                        <button type="submit" className="primary-btn px-5 mt-5"> Send &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {loading ? <Spinner animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} /> : <BsArrowRight />}</button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal size="xl" centered show={show1} onHide={() => setShow1(false)}>
            <Modal.Body className="text-left text-white" style={{ backgroundColor: "#021228", padding: "120px" }}>
                    <div className="text-end"><AiOutlineCloseCircle onClick={() => setShow1(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    <div className="row mt-0 mb-3">
                        <div className="col-md-4">
                            <img src={`/legal-tech-uploads/${viewMore1?.ImgLocation}`} height="100%" width="100%" alt="" />
                            {viewMore?.isVerify && <BsPatchCheck size={50} className="text-primary" style={{ position: "absolute", float: "right", margin: "250px 0px 0px -80px" }} />}
                        </div>
                        <div className="col-md-8 px-5">

                            <table width="100%">
                                <tr>
                                    <td><span><b>{viewMore1?.companyName}</b></span> <br /><span> Dispute Law Firm</span></td>
                                    <td><span><b>Phone Number</b> </span> <br /><span> {viewMore1?.phoneNumber}</span></td>
                                    <td><span><b>Website</b> </span> <br /><span> {viewMore1?.website}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td><span><b>Email Address</b> </span> <br /><span>{viewMore1?.emailAddress}</span></td>
                                    <td><span><b>Location</b> </span> <br /><span> {viewMore1?.Location}</span></td>
                                    <td><span><b>Designation</b> </span> <br /><span> {viewMore1?.areaOfPractice}</span></td>
                                </tr>
                                <tr><br /><br /></tr>
                                <tr>
                                    <td colSpan={2}><span><b>About {viewMore1?.firstName} {viewMore1?.lastName}</b> </span> <br /><span>{viewMore1?.BriefBio}</span></td>
                                </tr>
                                <tr><br /></tr>
                                <tr><td colSpan={2}><button onClick={() => setShowSendMessage1(!showSendMessage1)} className="btn text-white"> <FaRegEnvelope /> &nbsp; Send a message</button></td></tr>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="md" centered show={showSendMessage1} onHide={() => setShowSendMessage1(false)} style={{ backgroundColor: "#0A3B81F7" }}>
                <Modal.Body style={{ backgroundColor: "#0A3B81F7", border: "#FFFFFF solid 1px", borderRadius: "20px" }}>
                    <div className="text-end text-white"><AiOutlineCloseCircle onClick={() => setShowSendMessage1(false)} style={{ cursor: "pointer", fontSize: "30px", margin: "-50px -50px 0px 0px" }} /></div>
                    <Form action="" onSubmit={handleMessageLawFirm} className="px-4 py-5 text-center text-white">
                        <h4>Send a <b className="fw-bold">message</b></h4>
                        <Form.Control type="text" className="mt-5 px-3 py-3" placeholder="Name" onChange={(e) => setSendMessageLawFirm({ ...sendMessageLawFirm, senderName: e.target.value })}></Form.Control>
                        <Form.Control type="text" className="mt-4 px-3 py-3" placeholder="Email" onChange={(e) => setSendMessageLawFirm({ ...sendMessageLawFirm, senderEmail: e.target.value })}></Form.Control>
                        <Form.Control as="textarea" className="mt-4 px-3 py-3" onChange={(e) => setSendMessageLawFirm({ ...sendMessageLawFirm, message: e.target.value })} placeholder="Your Message" rows={5} />
                        <button type="submit" className="primary-btn px-5 mt-5"> Send &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {loading ? <Spinner animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} /> : <BsArrowRight />}</button>
                    </Form>
                </Modal.Body>
            </Modal>
        </main>
    )
}

export default LandingPage;
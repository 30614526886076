import React, { useEffect, useState } from 'react';
import '../styles/Sidebar.css';
import Logo from '../assets/legal-fuse.png';
import { NavbarBrand, NavDropdown, NavLink } from 'react-bootstrap';
import { MdDashboard, MdGavel, MdLogout, MdOutlineNotificationsNone, MdSettings } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import { IoIosNotificationsOutline } from 'react-icons/io'
import client from '../api/client';
import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ children, pageTitle }) => {
    const [isCollapsed, setCollapsed] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const toggleSidebar = () => {
        setCollapsed(!isCollapsed);
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // Create a function to update the window size when it changes
    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    // Attach the event listener when the component mounts
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLogout = () => {
        if (localStorage.getItem("user") !== null && localStorage.getItem("user") !== undefined) {
            window.localStorage.removeItem("user");
            navigate('/');
        }
    }

    const sidebarAuth = JSON.parse(localStorage.getItem("user"));

    return (
        <section className='layout-section'>
            <div className='row m-0 p-0 h-100'>
                {windowSize.width > 500 && <div className='col-2 pt-4 pb-5 px-0'>
                    <NavLink href='/'><div className='px-5 mt-2 logo-brand'><img src={Logo} width="40px" /> &nbsp; <span className='my-auto text-dark fs-5 fw-bold'>LEGAL</span> <span className='text-primary fs-5 fw-bold'>FUSE</span></div></NavLink>
                    {sidebarAuth?.data?.userName == "admin@legalfuse.com" ? <div className='mt-5'>
                        <NavLink href="/./admin/dashboard" className={location.pathname === "/admin/dashboard" ? 'sidebar-menu-active w-100 px-5 py-3 d-flex justify-content-between' : 'sidebar-menu w-100 px-5 py-3 d-flex justify-content-between'}> Dashboard <MdDashboard size={35} className='p-1' /> </NavLink>
                        <NavLink href="/./admin/lawyers" className={location.pathname === "/admin/lawyers" ? 'sidebar-menu-active w-100 px-5 py-3 d-flex justify-content-between' : 'sidebar-menu w-100 px-5 py-3 d-flex justify-content-between'}> Lawyers <FaUser size={35} className='p-1' /> </NavLink>
                        <NavLink href="/./admin/lawfirms" className={location.pathname === "/admin/lawfirms" ? 'sidebar-menu-active w-100 px-5 py-3 d-flex justify-content-between' : 'sidebar-menu w-100 px-5 py-3 d-flex justify-content-between'}> Law Firms <MdGavel size={35} className='p-1' /> </NavLink>
                    </div>
                    : 
                    <div className='mt-5'>
                        <NavLink href="/dashboard" className={location.pathname === "/dashboard" ? 'sidebar-menu-active w-100 px-5 py-3 d-flex justify-content-between' : 'sidebar-menu w-100 px-5 py-3 d-flex justify-content-between'}> Dashboard <MdDashboard size={35} className='p-1' /> </NavLink>
                        <NavLink href="/profile" className={location.pathname === "/profile" ? 'sidebar-menu-active w-100 px-5 py-3 d-flex justify-content-between' : 'sidebar-menu w-100 px-5 py-3 d-flex justify-content-between'}> Profile <FaUser size={35} className='p-1' /> </NavLink>
                        <NavLink href="/settings" className={location.pathname === "/settings" ? 'sidebar-menu-active w-100 px-5 py-3 d-flex justify-content-between' : 'sidebar-menu w-100 px-5 py-3 d-flex justify-content-between'}> Settings <MdSettings size={35} className='p-1' /> </NavLink>
                    </div>
                    }
                </div>}
                <div className={windowSize.width < 500 ? 'col-12 p-0 bg-light' : 'col-10 p-0 bg-light'}>
                    <header className='dashboard-header w-100 d-flex justify-content-between bg-white px-5 pt-4 pb-3'>
                        <h5>{pageTitle}</h5>
                        {sidebarAuth?.data?.userName == "admin@legalfuse.com" ? <div className='d-flex justify-content-between'>
                            <div className='px-4 text-secondary'><IoIosNotificationsOutline size={30} /></div>
                            {windowSize.width > 500 && <div style={{ padding: "0px 25px", borderLeft: "1px solid grey", borderRight: "1px solid grey" }}><span className='text-primary d-block'>Admin </span><font className='text-secondary'>Admin id : {sidebarAuth?.data?.id}</font></div>}
                            <div className='ps-4'>
                                <NavDropdown title={<FaUser size={35} className='border text-primary rounded-circle p-1' />} id="nav-dropdown">
                                    <NavDropdown.Item href="/./admin/dashboard" className='text-secondary py-2 fs-6'><MdDashboard size={20} />    &nbsp;Dashboard</NavDropdown.Item>
                                    <NavDropdown.Item href="/./admin/lawyers" className='text-secondary py-2 fs-6'><FaUser size={20} />&nbsp;&nbsp;Lawyers</NavDropdown.Item>
                                    <NavDropdown.Item href="/./admin/lawfirms" className='text-secondary py-2 fs-6'><MdGavel size={20} />&nbsp;&nbsp;Law Firms</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLogout()} className='text-secondary py-2 fs-6'><MdLogout size={20} /> &nbsp;Logout</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </div>
                        :
                        <div className='d-flex justify-content-between'>
                            <div className='px-4 text-secondary'><IoIosNotificationsOutline size={30} /></div>
                            {windowSize.width > 500 && <div style={{ padding: "0px 25px", borderLeft: "1px solid grey", borderRight: "1px solid grey" }}><span className='text-primary d-block'>User </span><font className='text-secondary'>User id : {sidebarAuth?.data?.id}</font></div>}
                            <div className='ps-4'>
                                <NavDropdown title={<FaUser size={35} className='border text-primary rounded-circle p-1' />} id="nav-dropdown">
                                    <NavDropdown.Item href="/dashboard" className='text-secondary py-2 fs-6'><MdDashboard size={20} />    &nbsp;Dashboard</NavDropdown.Item>
                                    <NavDropdown.Item href="/profile" className='text-secondary py-2 fs-6'><FaUser size={20} />&nbsp;&nbsp;Profile</NavDropdown.Item>
                                    <NavDropdown.Item href="/settings" className='text-secondary py-2 fs-6'><MdSettings size={20} />&nbsp;&nbsp;Settings</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLogout()} className='text-secondary py-2 fs-6'><MdLogout size={20} /> &nbsp;Logout</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </div>
                        }
                    </header>
                    <div className='px-5 py-4'>
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Sidebar;



import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import about from '../../images/Rectangle 37.png';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "../../styles/Blog.module.css";

const Blog2 = () => {

    document.title = "Contact Us - Legal Fuse";

    return (
        <section>
            <Header />
            <div className="container mt-5 mb-5 pb-5">
                <div className="row col-md-10 m-auto">
                    <h3 className={styles.title}>Law and fake news in Nigeria</h3>
                    <img src="https://guardian.ng/wp-content/uploads/2023/04/Gbolahan-Elias.jpg" className="mb-3 mt-3" />
                    <p><b>Attorney-General of the Federation, Abubakar Malami (SAN)</b></p>
                    <p>The Nigerian National elections held February 23, 2019 and campaigns were in full swing before then. Candidates of over 70 registered political parties simultaneously campaigned for votes and tried to prove why Nigerian voters should come out to vote for them on Election Day. The meteoric rise of digital media moved the majority of the politicking to the internet as politicians try to reach most middle-class voters in their place of comfort; social media. However, every action has a reaction, and while the digital media has helped “disrupt” Nigerian Political campaigns, it has also aided the rise of the dreaded “Fake News”.</p>
                    <p>Dictionary.com defines Fake News as false new stories, often spread as propaganda on social media. It goes on to describe it as any information that one finds critical about themselves. A leading scholar on the subject, Bente Kalsnes identified three essential elements of Fake News, these are; format, falsity and intention. It must be noted that while Kalsnes features are quite comprehensive, not all Fake News contain false information. Most of the time, Fake News contains accurate yet misleading information. For the purposes of this article, Fake News is as any piece of false or misleading information that is intentionally published with the intent to mislead and misdirect the public on a subject matter.</p>
                    <p> There are various forms of Fake News, the most common include.</p>
                    <p><b>Satire or Parody</b> <br />In satire, there is no intention to cause serious harm. However, it has the potential to cause devastating damage. Examples of parody is the Twitter account “Parosident Buhari” which has been a thorn in the side for the social media team of the president for the last four years, another is the handle “@reaIFemiOtedoIa” which has been impersonating and conducting giveaways on behalf of the Lagos based Billionaire, Femi Otedola for months. It also includes many other celebrity parody accounts on social media. Many of these parody accounts are couriers of Fake News. Many of those accounts are also involved in attempts to defraud unsuspecting members of the public through different avenues.</p>
                    <p><b>False Connection</b><br />
                        This is also known as click baiting: it occurs when the headlines intentionally fail to match the content of the information with the assumption that viewers will simply read the headline and accept the falsehood.</p>
                    <p> Misleading Content
                        This is also called sound biting, this occurs when a person takes a quote of an individual out of context and turns it to the headline, for example, when American Media convinced the entire world that President Trump reportedly said Latinas are “vicious animals” when he actually was talking about members of a dreaded gang “MS-13”.</p>
                    <p> False Context
                        This occurs when the news is framed to make the truth come out as a lie in order to misinform the public on the true state of affairs.</p>
                    <p> Impostor Content
                        This occurs where the entire content of news is revealed to be false. An example is the infamous “interview” of banking titan, Ibukun Awosika in the Nation Newspaper that was later revealed to be false and non-existent.</p>
                    <p> Manipulated Content
                        This is commonly known as “Photoshop” this occurs when information is manipulated in order to deceive. This is common with pictures, but advances in technology have created a new mode of manipulation for videos called “Deepfake”. Deepfake occurs when a computer algorithm is trained to recognise patterns in actual audio or visual recordings of a particular person, a process known as deep learning with the view to mimic the voice and face of an individual in a manner that cannot be distinguished from the original. It thus can be used to devastating effect and has the potential to be the most dangerous form of Fake News</p>
                    <p> Fabricated Content
                        This is simply content that is completely false that is distributed with the sole aim of causing harm.</p>
                    <p> The rationale behind Fake News is to create an atmosphere of disinformation, confusion, fear, anger, and hysteria where consumers of the media cannot differentiate the truth from lies. Constant consumption of Fake News ensures consumers develop deep-set preconceived notions and opinions on people and events due to the deception they are fed with. Indeed, the father of Fake News/analysis was Joseph Goebbels, who reasoned that if you incessantly repeat a lie, the lie would become “reality”. Legally, the Nigerian legal framework has no distinct mention of Fake News, as the concept is a modern phenomenon.</p>
                    <p> It must be noted that the right to freedom of speech and expression provide for in section 39 (1) of the Constitution of the Federal Republic of Nigeria as Amended is complete and without limitations generally. However, the right to freedom of speech and expression does not exist in a vacuum. The tort of defamation can be instituted against speech and expression that is false with monetary damages and a public apology awarded if the case is successful.</p>
                    <p> Furthermore, a piece of news that is defamatory may be punished with imprisonment for two years under section 60 of the Criminal Code Act. In a situation where false news is made against the state and is thus seditious, the culprit could be imprisoned upon conviction for two years under section 51 of the Criminal Code Act. Municipally, Kaduna state is currently working on a law to combat Fake News. It is quite unfortunate that despite all these sources of law there is a lack of judicial authority on the subject matter.<br/>
                        Digital media has helped “disrupt” nigerian political campaigns; it has also aided the rise of the dreaded “fake news”</p>
                    <p> Nigerians have always had to combat an agenda driven news since independence. This problem significantly increased due to the explosion of digital media. The situation is made worse by Portland’s 2018 study into ‘How Africa Tweets’ which found a whopping 53 per cent of the prominent Twitter accounts around ten electoral cycles in Africa in 2017 came from outside the state where elections were ongoing. The study concluded that African governments are not immune to global issues such as Fake News, the rise of bots, troll farms and external influence on elections. These statistics took a new light as a 2018 report by The Guardian says Cambridge Analytica; the embattled data analysis firm that reportedly used the personal data of 50 million Facebook members to plant Fake News in a bid to influence the US presidential election in 2016 was also involved in the Nigerian 2015 elections. The report states that Cambridge Analytica was hired by an unnamed Nigerian billionaire to work on the re-election campaign of then-president Goodluck Jonathan. The data analytics firm “was paid an estimated £2 million (₦ 935 million) to orchestrate a ferocious campaign” against Muhammadu Buhari, the leading opposition candidate at the time.</p>
                    <p> The result was a slew of widely distributed short propaganda videos, which raised allegations that then Mr Buhari (now President Buhari) was a promoter of sectional violence. Since then every election in Nigeria has witnessed few bouts of Fake News where a party would circulate an old picture of money in an envelope or strapped on packs of food as the case may be and accuse the opposition of attempting to buy votes; there is also the incidence of doctored pictures of crowd size at electoral campaigns. One can also point to the misinformation following Boko Haram and killer herdsmen attacks where fake photographs were widely circulated; in all these cases, however, social media users were able to expose the frauds on their own and spread the word.</p>
                    <p> Fake News now reached a crescendo because of the national elections. One can point out the rapid spread of the “Jubril from Sudan” rumours that made rounds in Nigeria and now around the world as a testament to the fact that Fake News is now a problem that must be combatted. The situation worsens as politicians have now taken advantage of the situation to call news that does not favour them Fake News.</p>
                    <p> There is a need for a Private-Public partnership to combat Fake News in Nigeria. This partnership should be directed towards building strong institutions that can independently fact check news in real time and as well as enlighten Nigerians on the adverse effect of Fake News. To this end, the Independent National Electoral Commission (INEC) has taken a big step in the right direction by commencing a training program for its accredited officials during the 2019 elections themed Fake News, Disinformation and Media Monitoring. The Executive Director, European Centre for Electoral Support (ECES), Mr Fabio Bargiachi, revealed that ECES had supplied hard and software to be installed recently in establishing National Media Centre for INEC. Additionally, 16 Nigerian news organisations are being trained to fight misinformation and disinformation focusing on dissemination through Whatsapp (biggest purveyor of information and thus Fake News in Nigeria).</p>
                    <p> Globally, the problem of Fake News is viewed seriously and is one that must be tackled by all stakeholders if good governance is to be achieved. Different governments have taken different stances to address the blight. Germany for instance in June 2017, adopted the Network Enforcement Act (NetzDG), which targeted specific content on social media such as, items related to terrorism, false information, child pornography and hate speech.</p>
                    <p> The Act punishes social media platforms like Facebook and Twitter with hefty fines of up to 50 million Euros if they fail to remove such illegal content. France is working on a controversial statute, which would enable a candidate, or a political party apply to the court to order an instant halt to the publication of any information, which is adjudged to be fabricated in the three months leading up to the opening of the polls for the National election.</p>
                    <p> Brazil had a draft bill that failed after widespread protests, the bill provided for convictions for as much as three years for the dispersal of false information on the internet “related to health, security, the national economy, the electoral process or all other subjects of public interest”.</p>
                    <p> Malaysia and Kenya also enacted the controversial Fake News Act of 2018 and Computer Misuse, and Cybercrimes Act, 2018, respectively. Both statutes criminalise the dissemination of Fake News. Human and internet rights activists have criticised all these legislations as having the potential to stifle authentic and reasonable free speech by encouraging social media platforms to disproportionately and arbitrarily delete and repress posts as a deterrent as well as through the threat of substantial fine and imprisonment.</p>
                    <p> In conclusion, Nigeria cannot afford to stick its head in the sand, as the potential devastation that can be caused by Fake News is too great to be overlooked. There is a need for a well thought out definition of Fake News by Nigerian statute as well as a policy and legislative strategy to combat Fake News. Pending the creation of legal measures to combat Fake News, self-regulation through the respective social media platforms, NGO’s such as Paradigm Initiative Nigeria (PIN) as well as Crosscheck Nigeria blog, which will fact check and verify political information going out during elections. Finally, the vigilant reader will need to take steps to verify all Facebook and Twitter posts and WhatsApp broadcast messages the reader sees before reposting. It is only together that we can combat the menace of Fake News.
                    </p>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default Blog2;
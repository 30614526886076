import React, { useState } from "react";
import client from "../../api/client";
import '../../styles/admin.css';
import { Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsChevronRight } from "react-icons/bs";

const AdminLogin = () => {

    document.title = "Admin Login - Legal Fuse";

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [checkPassword, setCheckPassword] = useState("password");

    const [signIn, setSignIn] = useState();

    const [errorLogin, setErrorLogin] = useState();
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await client.post('/admin/authentication/login', signIn);
        if (res.data.statusCode === 200) {
            window.localStorage.setItem("user", JSON.stringify(res.data));
            setLoading(false);
            navigate('/admin/dashboard');
        }
        else {
            setLoading(false);
            setErrorLogin(
                <Alert variant="danger">
                    {res.data.message}
                </Alert>
            );
        }
    };

    return (
        <section className="adminbg">
            <div className="row mx-2">
                <Form action="" onSubmit={handleLogin} className="px-5 py-5 text-white col-md-4 m-auto" style={{ backgroundColor: "hsla(0, 1%, 59%, 0.4)", border: "#FFFFFF solid 1px", borderRadius: "20px" }}>
                    <h3 align="center">Administrator</h3>
                    <h4 align="center">Login <b>Here</b></h4>
                    <p>{errorLogin}</p>

                    <Form.Control type="email" required onChange={(e) => setSignIn({ ...signIn, email: e.target.value })} placeholder="Email Address" className="searchInput mb-4 mt-5"></Form.Control>

                    <div className="d-flex">
                        <Form.Control type={checkPassword} required onChange={(e) => setSignIn({ ...signIn, password: e.target.value })} placeholder="Password" className="searchInput mb-2"></Form.Control>
                        <div style={{ margin: "15px -50px", color: "grey" }}>{checkPassword == "password" ? <AiOutlineEyeInvisible size={25} onClick={() => setCheckPassword("text")} /> : <AiOutlineEye size={25} onClick={() => setCheckPassword("password")} />}</div></div>

                    <div className="text-center mt-4 mb-5"><button type="submit" className="primary-btn px-5 py-3 mt-4">Login &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button></div>

                </Form>
            </div>
            {/* <div className="container text-center mt-auto mb-5 pt-4 pb-4">
                <div className="card p-5 row col-md-5 m-auto">
                    <Form action="" onSubmit={handleLogin} className="onboardingForm">
                        <h3 align="center">Administrator</h3>
                        <h5 align="center">Login here</h5>
                        <p>{errorLogin}</p>
                        <div className="row text-start">
                            <div className="col-md-12">
                                <Form.Label className="formLabel">&nbsp;&nbsp;Email Address:</Form.Label>
                                <Form.Control type="email" required onChange={(e) => setSignIn({ ...signIn, email: e.target.value })} placeholder="johnmark@yahoo.com" className="inputField"></Form.Control>
                            </div>
                        </div>
                        <div className="row text-start mt-4">
                            <div className="col-md-12">
                                <Form.Label className="formLabel">&nbsp;&nbsp;Password</Form.Label>
                                <Form.Control type="password" required onChange={(e) => setSignIn({ ...signIn, password: e.target.value })} placeholder="***********" className="inputField"></Form.Control>
                            </div>
                        </div>
                        <div className="text-center"><button type="submit" className="backButton">Login &nbsp; <Spinner hidden={loading} animation="border" variant="light" style={{ fontSize: "10px", height: "25px", width: "25px", verticalAlign: "middle" }} /></button></div>
                    </Form>
                </div>
            </div> */}
        </section>
    )
}

export default AdminLogin;
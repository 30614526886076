import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import client from "../../api/client";
import Header from "../../components/Header";
import styles from "../../styles/ProfilePage.module.css";
import logo from "../../images/legal-fuse.png";
import { Form, Navbar, NavDropdown } from "react-bootstrap";
import Select from "react-select";
import { FaUserCircle } from "react-icons/fa";
import Alert from "react-bootstrap/Alert";
import jwtDecode from "jwt-decode";
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineEdit } from "react-icons/ai";
import Sidebar from "../../components/Layout";
import { BsChevronRight } from "react-icons/bs";


const ProfilePage = () => {

    document.title = "Account Profile Dashboard - Legal Fuse";

    const navigate = useNavigate();

    const getUser = JSON.parse(localStorage.getItem("user"));

    const authenticate = () => {
        if (localStorage.getItem("user") === null || localStorage.getItem("user") === undefined) {
            navigate('/');
        }
    }

    useEffect(() => {
        authenticate();
    }, [getUser]);


    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const [updateProfile, setUpdateProfile] = useState();
    const [updateCompany, setUpdateCompany] = useState();
    const [status, setStatus] = useState();

    const initialResponsiblePersonsArray = getUser?.data?.responsiblePerson.split(',');

    // Create the initial state using the array
    const initialInputList = initialResponsiblePersonsArray.map(person => ({
        responsiblePerson: person
    }));

    const [inputList, setInputList] = useState(initialInputList);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { responsiblePerson: "" }]);
    };

    const handleUpdateCompany = async (e) => {
        e.preventDefault();
        setLoading(true);
        //AreaOfPractice: skillOptions1.value, AreaOfExpertise: skillOptions.value
        const input = {
            "emailAddress": updateCompany?.emailAddress === undefined || updateCompany?.emailAddress === null ? getUser?.data?.emailAddress : updateCompany?.emailAddress,
            "companyName": updateCompany?.companyName === undefined || updateCompany?.companyName === null ? getUser?.data?.companyName : updateCompany.companyName,
            "address": updateCompany?.address === undefined || updateCompany?.address === null ? getUser?.data?.address : updateCompany?.address,
            "Location": updateCompany?.Location === undefined || updateCompany?.Location === null ? getUser?.data?.Location : updateCompany?.Location,
            "phoneNumber": updateCompany?.phoneNumber === undefined || updateCompany?.phoneNumber === null ? getUser?.data?.phoneNumber : updateCompany?.phoneNumber,
            "areaOfPractice": skillOptions1?.value === undefined || skillOptions1?.value === null ? getUser?.data?.areaOfPractice : skillOptions1?.value,
            "responsiblePerson": inputList?.map(item => item.responsiblePerson) === undefined || inputList?.map(item => item.responsiblePerson) === null ? getUser?.data?.responsiblePerson : inputList?.map(item => item?.responsiblePerson),
            "website": updateCompany?.website === undefined || updateCompany?.website === null ? getUser?.data?.website : updateCompany?.website,
            "BriefBio": updateCompany?.BriefBio === undefined || updateCompany?.BriefBio === null ? getUser?.data?.BriefBio : updateCompany?.BriefBio
        };
        const res = await client.put(`/firm/authentication/updateprofile/${getUser?.data?.id}`, input);
        if (res.data.statusCode === 200) {
            setLoading(false);
            const updatedData = {
                "token": getUser?.token,
                "data": res?.data?.data
            };
            window.localStorage.setItem("user", JSON.stringify(updatedData));
            setStatus(<Alert variant="success"> Your Profile As Been {res.data.message} </Alert>);
        }
        else {
            setStatus(<Alert variant="danger"> {res.data.message} </Alert>);
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        //AreaOfPractice: skillOptions1.value, AreaOfExpertise: skillOptions.value
        const input = {
            "email": updateProfile?.email === undefined || updateProfile?.email === null ? getUser?.data?.email : updateProfile?.email,
            "firstName": updateProfile?.firstName === undefined || updateProfile?.firstName === null ? getUser?.data?.firstName : updateProfile?.firstName,
            "lastName": updateProfile?.lastName === undefined || updateProfile?.lastName === null ? getUser?.data?.lastName : updateProfile?.lastName,
            "addrress": updateProfile?.addrress === undefined || updateProfile?.addrress === null ? getUser?.data?.addrress : updateProfile?.addrress,
            "Location": updateProfile?.Location === undefined || updateProfile?.Location === null ? getUser?.data?.Location : updateProfile?.Location,
            "phoneNumber": updateProfile?.phoneNumber === undefined || updateProfile?.phoneNumber === null ? getUser?.data?.phoneNumber : updateProfile?.phoneNumber,
            "nbaNumber": updateProfile?.nbaNumber === undefined || updateProfile?.nbaNumber === null ? getUser?.data?.nbaNumber : updateProfile?.nbaNumber,
            "supremeCourtNumber": updateProfile?.supremeCourtNumber === undefined || updateProfile?.supremeCourtNumber === null ? getUser?.data?.supremeCourtNumber : updateProfile?.supremeCourtNumber,
            "AreaOfPractice": skillOptions1?.value === undefined || skillOptions1?.value === null ? getUser?.data?.AreaOfPractice : skillOptions1?.value,
            "AreaOfExpertise": skillOptions?.value === undefined || skillOptions?.value === null ? getUser?.data?.AreaOfExpertise : skillOptions?.value,
            "BriefBio": updateProfile?.BriefBio === undefined || updateProfile?.BriefBio === null ? getUser?.data?.BriefBio : updateProfile.BriefBio
        };
        const res = await client.put(`/lawyer/authentication/updateprofile/${getUser?.data?.id}`, input);
        if (res.data.statusCode === 200) {
            setLoading(false);
            const updatedData = {
                "token": getUser?.token,
                "data": res.data.data
            };
            window.localStorage.setItem("user", JSON.stringify(updatedData));
            setStatus(<Alert variant="success"> Your Profile As Been {res.data.message} </Alert>);
        }
        else {
            setStatus(<Alert variant="danger"> {res.data.message} </Alert>);
        }
    }

    const [skillOptions, setSkillOptions] = useState(null);
    const [skillOptions1, setSkillOptions1] = useState(null);

    const options = [
        { value: 'Banking', label: 'Banking' },
        { value: 'Capital Markets (Debt)', label: 'Capital Markets (Debt)' },
        { value: 'Capital Markets (Equity)', label: 'Capital Markets (Equity)' },
        { value: 'Election Petition', label: 'Election Petition' },
        { value: 'M&A', label: 'M&A' },
        { value: 'Project Developmet (Energy)', label: 'Project Developmet (Energy)' },
        { value: 'Project Development (Infrastruture)', label: 'Project Development (Infrastruture)' },
        { value: 'Restructuring and Insolvency', label: 'Restructuring and Insolvency' }
    ];

    const options1 = [
        { value: 'Corporate Commercial', label: 'Corporate Commercial' },
        { value: 'Arbitration', label: 'Arbitration' },
        { value: 'Dispute', label: 'Dispute' },
        { value: 'Bankruptcy', label: 'Bankruptcy' },
        { value: 'Business Law', label: 'Business Law' },
        { value: 'Car Accident', label: 'Car Accident' },
        { value: 'Criminal Defense', label: 'Criminal Defense' },
        { value: 'Employment', label: 'Employment' },
        { value: 'Estate Planning', label: 'Estate Planning' },
        { value: 'Family Law', label: 'Family Law' },
        { value: 'Government', label: 'Government' },
        { value: 'Health Care & Social', label: 'Health Care & Social' },
        { value: 'Immigration', label: 'Immigration' },
        { value: 'Industry', label: 'Industry' },
        { value: 'Intellectual Property', label: 'Intellectual Property' },
        { value: 'International Law', label: 'International Law' },
        { value: 'Litigation', label: 'Litigation' },
        { value: 'Medical Malpractice', label: 'Medical Malpractice' },
        { value: 'Personal Injury', label: 'Personal Injury' },
        { value: 'Real Estate', label: 'Real Estate' },
        { value: 'Tax', label: 'Tax' }
    ];


    return (
        <Sidebar pageTitle={"User Profile"}>
            <div className="card p-5">
                {getUser?.data?.firstName === null || getUser?.data?.firstName === undefined ?
                    <div className="row">
                        <div className="col-md-12">
                            <Form action="" onSubmit={handleUpdateCompany}>
                                <h5>Update Company profile</h5>
                                {status}
                                <div className="row">
                                    <div className="col-md-4 mt-4">
                                        <Form.Label>&nbsp;&nbsp;Company Name *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.companyName} placeholder="Mark" onChange={(e) => setUpdateCompany({ ...updateCompany, companyName: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <Form.Label>&nbsp;&nbsp;Company Website *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.website} placeholder="Mark" onChange={(e) => setUpdateCompany({ ...updateCompany, website: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <Form.Label>&nbsp;&nbsp;Email Address *</Form.Label>
                                        <Form.Control type="email" disabled required defaultValue={getUser?.data?.emailAddress} placeholder="mark.j@gmail.com" onChange={(e) => setUpdateCompany({ ...updateCompany, emailAddress: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;Phone Number *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.phoneNumber} placeholder="08976554321" onChange={(e) => setUpdateCompany({ ...updateCompany, phoneNumber: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Label for="locations">&nbsp;&nbsp;Location *</Form.Label>
                                        <Form.Select name="locations" id="locations" required className="searchInput" onChange={(e) => setUpdateCompany({ ...updateCompany, Location: e.target.value })}>
                                            {getUser?.data?.Location === null || getUser?.data?.Location === undefined ? <option value="">Select location</option> : <option defaultValue={getUser?.data?.Location} selected>{getUser?.data?.Location}</option>}
                                            <option value="Abia">Abia</option>
                                            <option value="Adamawa">Adamawa</option>
                                            <option value="Akwa Ibom">Akwa Ibom</option>
                                            <option value="Anambra">Anambra</option>
                                            <option value="Bauchi">Bauchi</option>
                                            <option value="Bayelsa">Bayelsa</option>
                                            <option value="Benue">Benue</option>
                                            <option value="Borno">Borno</option>
                                            <option value="Cross River">Cross River</option>
                                            <option value="Delta">Delta</option>
                                            <option value="Ebonyi">Ebonyi</option>
                                            <option value="Edo">Edo</option>
                                            <option value="Ekiti">Ekiti</option>
                                            <option value="Enugu">Enugu</option>
                                            <option value="FCT">Federal Capital Territory</option>
                                            <option value="Gombe">Gombe</option>
                                            <option value="Imo">Imo</option>
                                            <option value="Jigawa">Jigawa</option>
                                            <option value="Kaduna">Kaduna</option>
                                            <option value="Kano">Kano</option>
                                            <option value="Katsina">Katsina</option>
                                            <option value="Kebbi">Kebbi</option>
                                            <option value="Kogi">Kogi</option>
                                            <option value="Kwara">Kwara</option>
                                            <option value="Lagos">Lagos</option>
                                            <option value="Nasarawa">Nasarawa</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Ogun">Ogun</option>
                                            <option value="Ondo">Ondo</option>
                                            <option value="Osun">Osun</option>
                                            <option value="Oyo">Oyo</option>
                                            <option value="Plateau">Plateau</option>
                                            <option value="Rivers">Rivers</option>
                                            <option value="Sokoto">Sokoto</option>
                                            <option value="Taraba">Taraba</option>
                                            <option value="Yobe">Yobe</option>
                                            <option value="Zamfara">Zamfara</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;Area of Practice *</Form.Label>
                                        <Select required
                                            // isMulti
                                            name="skills"
                                            defaultValue={options1.filter(option => option.label === getUser?.data?.areaOfPractice)}
                                            options={options1}
                                            onChange={setSkillOptions1}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div><Form.Label>&nbsp;&nbsp;Company Address *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.address} placeholder="Ikeja, Lagos" onChange={(e) => setUpdateCompany({ ...updateCompany, addrress: e.target.value })} className="searchInput"></Form.Control>
                                        </div>
                                    <div className="row">
                                        <Form.Label>&nbsp;&nbsp;Update Responsible Person. *     <span>(Maximum of 3)</span></Form.Label>
                                        {inputList.map((x, i) => {
                                            return (
                                                <div className="col-md-6">
                                                    <Form.Control type="text" required name="responsiblePerson" placeholder="Principal 1 person's name" value={x.responsiblePerson}
                                                        onChange={e => handleInputChange(e, i)} className="searchInput mb-1"></Form.Control>
                                                    <div className="m-0">
                                                        {inputList.length !== 1 && <button
                                                            className="bg-danger text-light"
                                                            onClick={() => handleRemoveClick(i)} style={{ border: "none", borderRadius: "5px", padding: "5px 10px", marginRight: "10px", marginBottom: "10px" }}>Remove</button>}
                                                        {inputList.length < 3 && inputList.length - 1 === i && <button className="bg-success text-light" onClick={handleAddClick} style={{ border: "none", borderRadius: "5px", padding: "5px 10px" }}>Add</button>}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Label>&nbsp;&nbsp;About the Law Firm*</Form.Label>
                                        <Form.Control as="textarea" required defaultValue={getUser?.data?.BriefBio} placeholder="Tell us about yourself" rows={7} onChange={(e) => setUpdateCompany({ ...updateCompany, BriefBio: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                </div>
                                {/* <div className="row mt-4 mb-4">
                                    <div className="col-md-12">
                                        <Form.Label>&nbsp;&nbsp;About the Law Firm*</Form.Label>
                                        <Form.Control as="textarea" required defaultValue={getUser?.data?.BriefBio} placeholder="Tell us about yourself" rows={3} onChange={(e) => setUpdateCompany({ ...updateCompany, BriefBio: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                </div> */}
                                <div className="text-end"><button type="submit" className="primary-btn">Update &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button>
                                </div>
                            </Form>
                        </div>
                    </div> :
                    <div className="row">
                        <div className="col-md-9">
                            <Form action="" onSubmit={handleUpdate} className={styles.updateProfile}>
                                <h4>Update your profile</h4>
                                {status}
                                <div className="row">
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;First Name *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.firstName} placeholder="Mark" onChange={(e) => setUpdateProfile({ ...updateProfile, firstName: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;Last Name *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.lastName} placeholder="John" onChange={(e) => setUpdateProfile({ ...updateProfile, lastName: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;Email Address *</Form.Label>
                                        <Form.Control type="email" disabled required defaultValue={getUser?.data?.email} placeholder="mark.j@gmail.com" onChange={(e) => setUpdateProfile({ ...updateProfile, email: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;Phone Number *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.phoneNumber} placeholder="08976554321" onChange={(e) => setUpdateProfile({ ...updateProfile, phoneNumber: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Label for="locations">&nbsp;&nbsp;Location *</Form.Label>
                                        <Form.Select name="locations" id="locations" className="searchInput" onChange={(e) => setUpdateProfile({ ...updateProfile, Location: e.target.value })}>
                                            {getUser?.data?.Location === null || getUser?.data?.Location === undefined ? <option value="">Select location</option> : <option defaultValue={getUser?.data?.Location} selected>{getUser?.data?.Location}</option>}
                                            <option value="Abia">Abia</option>
                                            <option value="Adamawa">Adamawa</option>
                                            <option value="Akwa Ibom">Akwa Ibom</option>
                                            <option value="Anambra">Anambra</option>
                                            <option value="Bauchi">Bauchi</option>
                                            <option value="Bayelsa">Bayelsa</option>
                                            <option value="Benue">Benue</option>
                                            <option value="Borno">Borno</option>
                                            <option value="Cross River">Cross River</option>
                                            <option value="Delta">Delta</option>
                                            <option value="Ebonyi">Ebonyi</option>
                                            <option value="Edo">Edo</option>
                                            <option value="Ekiti">Ekiti</option>
                                            <option value="Enugu">Enugu</option>
                                            <option value="FCT">Federal Capital Territory</option>
                                            <option value="Gombe">Gombe</option>
                                            <option value="Imo">Imo</option>
                                            <option value="Jigawa">Jigawa</option>
                                            <option value="Kaduna">Kaduna</option>
                                            <option value="Kano">Kano</option>
                                            <option value="Katsina">Katsina</option>
                                            <option value="Kebbi">Kebbi</option>
                                            <option value="Kogi">Kogi</option>
                                            <option value="Kwara">Kwara</option>
                                            <option value="Lagos">Lagos</option>
                                            <option value="Nasarawa">Nasarawa</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Ogun">Ogun</option>
                                            <option value="Ondo">Ondo</option>
                                            <option value="Osun">Osun</option>
                                            <option value="Oyo">Oyo</option>
                                            <option value="Plateau">Plateau</option>
                                            <option value="Rivers">Rivers</option>
                                            <option value="Sokoto">Sokoto</option>
                                            <option value="Taraba">Taraba</option>
                                            <option value="Yobe">Yobe</option>
                                            <option value="Zamfara">Zamfara</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;Place of Work *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.addrress} placeholder="Ikeja, Lagos" onChange={(e) => setUpdateProfile({ ...updateProfile, addrress: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;NBA Number *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.nbaNumber} placeholder="089735487FGJK" onChange={(e) => setUpdateProfile({ ...updateProfile, nbaNumber: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Label>&nbsp;&nbsp;Supreme Court Number *</Form.Label>
                                        <Form.Control type="text" required defaultValue={getUser?.data?.supremeCourtNumber} placeholder="234YHGJI" onChange={(e) => setUpdateProfile({ ...updateProfile, supremeCourtNumber: e.target.value })} className="searchInput"></Form.Control>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Label>&nbsp;&nbsp;Area of Practice *</Form.Label>
                                        <Select required
                                            // isMulti
                                            defaultValue={options1.filter(option => option.label === getUser?.data?.AreaOfPractice)}
                                            name="skills"
                                            options={options1}
                                            onChange={setSkillOptions1}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Label>&nbsp;&nbsp;Area of Expertise</Form.Label>
                                        <Select required
                                            // isMulti
                                            defaultValue={options.filter(option => option.label === getUser?.data?.AreaOfExpertise)}
                                            name="skills"
                                            options={options}
                                            onChange={setSkillOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <Form.Label>&nbsp;&nbsp;A bit about yourself *</Form.Label>
                                        <Form.Control as="textarea" required defaultValue={getUser?.data?.BriefBio} placeholder="Tell us about yourself" rows={3} onChange={(e) => setUpdateProfile({ ...updateProfile, BriefBio: e.target.value })}></Form.Control>
                                    </div>
                                </div>
                                <div className="text-end"><button type="submit" className="primary-btn">Update &nbsp; {loading ? <Spinner size="sm" animation="border" variant="light" /> : <BsChevronRight />}</button>
                                </div>
                            </Form>
                        </div>
                    </div>}
            </div>
        </Sidebar>
    )
}

export default ProfilePage;